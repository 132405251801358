import { makeAutoObservable, action, configure } from "mobx";

configure({
    enforceActions: "never" // This will disable strict mode.
});

class PatientStore {
    patientsToday = [];
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    newFetchPatients() {
        fetch('https://imac-receptionist-bot.glitch.me/today-appointments2')
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            this.patientsToday = data;
            console.log('Fetched patients:', data);
        })
        .catch(error => {
            console.error('Error fetching patients:', error);
        });
    }

    // fetchPatients() {
    //     fetch('https://imac-receptionist-bot.glitch.me/todays-appointments')
    //     .then(response => {
    //         if (!response.ok) {
    //             throw new Error('Network response was not ok');
    //         }
    //         return response.json();
    //     })
    //     .then(data => {
    //         this.patientsToday = data;
    //         console.log('Fetched patients:', data);
    //     })
    //     .catch(error => {
    //         console.error('Error fetching patients:', error);
    //     });
    // }

    setPatientsToday = action((newPatients) => {
        this.patientsToday = newPatients;
    });
    

    startFetchingPatientsHourly() {
        // Fetch immediately upon being called
        this.fetchPatients();

        // Then set up the interval
        const intervalId = setInterval(() => {
            this.fetchPatients();
        }, 3600000); // 3600000 ms = 1 hour

        // Return the intervalId so it can be cleared later if needed
        return intervalId;
    }
}

export default new PatientStore();
