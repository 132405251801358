import React, { useState, useEffect } from 'react';
import { Card, Table, CardDeck, Input, CardTitle, CardText, CardBody, Button, Container, Row, Col, Form, FormGroup, Label } from 'reactstrap';
import { Hearts } from "react-loader-spinner";
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/storage';
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { db } from '../firebase';
import { collection, addDoc, deleteDoc, getDoc, doc, updateDoc, query, getDocs, setDoc, where, onSnapshot, orderBy } from 'firebase/firestore';
import moment from 'moment';
import { parse } from 'exifr';
import { AiFillLeftCircle } from 'react-icons/ai';
import { FaThList, FaList } from 'react-icons/fa';
import { BsFillGridFill, BsFillCloudUploadFill, BsFillCalendarDateFill, BsArrowLeft } from 'react-icons/bs';
import { IoCloseSharp } from "react-icons/io5";


// Your Firebase config
const firebaseConfig = {
    apiKey: "AIzaSyDIWMqiBgKVPC9zpbPoR-q7bSURtAVdD0M",
    authDomain: "imac-medspa.firebaseapp.com",
    projectId: "imac-medspa",
    storageBucket: "imac-medspa.appspot.com",
    messagingSenderId: "209057704018",
    appId: "1:209057704018:web:495321765702b2b988d78b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

const SearchImages = () => {
    const [searchType, setSearchType] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [allResults, setAllResults] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [showFullScreen, setShowFullScreen] = useState(false);
    const [currentImage, setCurrentImage] = useState({});
    const [viewType, setViewType] = useState('grid'); // 'grid' or 'list'
    const [sortInfo, setSortInfo] = useState({ field: null, direction: 'asc' }); // 'asc' or 'desc'

    const [newImageType, setNewImageType] = useState(currentImage.type);
    const [newProcedure, setNewProcedure] = useState(currentImage.procedure);
    const [newNotes, setNewNotes] = useState(currentImage.notes);
    const [newId, setNewId] = useState(currentImage.docId);
    const [updater, setUpdater] = useState(false);


    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        const fetchAllPhotos = async () => {
            const q = collection(db, 'Photos');
            const querySnapshot = await getDocs(q);
            const results = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                results.push({ docId: doc.id, ...data });
            });
            const sortedResults = [...results].sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate));

            setAllResults(sortedResults);


            setSearchResults(sortedResults);
        };

        fetchAllPhotos();
    }, [updater]);

    const handleFullScreen = async (image) => {
        console.log(image)
        setCurrentImage(image);
        setNewId(image.docId);
        const docRef = doc(db, "Photos", image.docId);
        setShowFullScreen(true);

        try {
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                console.log("Document data:", docSnap.data());
                const docData = docSnap.data();
                setNewImageType(docData.type);
                setNewProcedure(docData.procedure);
                setNewNotes(docData.notes);
            } else {
                console.log("No such document!");
            }
        } catch (error) {
            console.error("Error getting document:", error);
        }


    };

    const handleCloseFullScreen = () => {
        setShowFullScreen(false);
    };

    const handleSearch = async () => {
        if (!searchType || !searchTerm) {
            setSearchResults(allResults);
            return;
        }

        let q;
        if (searchType === 'Patient Name') {
            q = allResults.filter(result => result.patient === searchTerm);
        } else if (searchType === 'Procedure') {
            q = allResults.filter(result => result.procedure === searchTerm);
        } else if (searchType === 'Tags') {
            q = allResults.filter(result => result.tags.includes(searchTerm));
        } else if (searchType === 'Date') {
            q = allResults.filter(result => result.uploadDate === searchTerm);
        }

        setSearchResults(q);
    };

    const sortResults = (field) => {
        let direction = 'asc';
        if (sortInfo.field === field) {
            direction = sortInfo.direction === 'asc' ? 'desc' : 'asc';
        }
        const sortedResults = [...searchResults].sort((a, b) => {
            if (a[field] < b[field]) return direction === 'asc' ? -1 : 1;
            if (a[field] > b[field]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
        setSortInfo({ field, direction });
        setSearchResults(sortedResults);
    };

    async function handleUpdateImage() {
        // event.preventDefault();

        // Object to hold the updates
        let updates = {};

        // Check each field and add to updates if there's a new value
        if (newImageType && newImageType !== currentImage.type) {
            updates.type = newImageType;
        }
        if (newProcedure && newProcedure !== currentImage.procedure) {
            updates.procedure = newProcedure;
        }
        if (newNotes && newNotes !== currentImage.notes) {
            updates.notes = newNotes;
        }

        // Reference to the specific document in the Firestore collection
        const docRef = doc(db, "Photos", currentImage.docId);

        try {
            // Update the document if there are updates
            if (Object.keys(updates).length > 0) {
                await updateDoc(docRef, updates);
                console.log('Document successfully updated');
            } else {
                console.log('No changes to update');
            }
        } catch (error) {
            console.error("Error updating document: ", error);
        }
        setUpdater(!updater);
    };

    const groupedResults = searchResults.reduce((groups, result) => {
        const date = result.uploadDate;
        if (!groups[date]) {
            groups[date] = [];
        }
        groups[date].push(result);
        return groups;
    }, {});

    return (
        <div>
            <div>Search for photos by:</div>
            <button className={"rounded-card mx-2"} onClick={() => setSearchType('Patient Name')}>Patient Name</button>
            <button className="rounded-card mx-2" onClick={() => setSearchType('Procedure')}>Procedure</button>
            <button className="rounded-card mx-2" onClick={() => setSearchType('Tags')}>Tags</button>
            <button className="rounded-card mx-2" onClick={() => setSearchType('Date')}>Date</button>
            <br />
            {searchType && (
                <>
                    <br />
                    <input
                        type={searchType === 'Date' ? 'date' : 'text'}
                        className="rounded-card mx-2 my-2"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button className="rounded-card mx-2" onClick={handleSearch}>Submit</button>
                </>
            )}
            <br /><br />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="text-left" style={{ fontStyle: 'italic' }}>
                    Searching by: {searchType} {searchTerm && ` - ${searchTerm}`}
                </div>
                <div style={{ fontSize: '1.5rem' }} type="button">
                    {
                        viewType === 'grid' && <BsFillGridFill onClick={() => setViewType('list')} />
                    }
                    {
                        viewType === 'list' && <FaThList onClick={() => setViewType('grid')} />
                    }
                </div>
            </div>

            {/* <div className="text-left" style={{ fontStyle: 'italic' }}>Searching by: {searchType} {searchTerm && ` - ${searchTerm}`}</div> */}
            {/* {searchResults.sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate)).map((result, index) => (
                        <div key={index} className={isMobile ? "text-center" : "text-left"}>
                            <img
                                src={result.imageURL}
                                alt={result.patient}
                                onClick={() => handleFullScreen(result)}
                                style={{ width: 100, height: 100, objectFit: 'cover' }}
                            /><br />
                        </div>
                    ))} */}
            {viewType === 'grid' ? (
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))', // Responsive columns
                    gap: '16px'
                }}>
                    {
                        Object.entries(groupedResults).map(([date, results]) => (
                            <React.Fragment key={date}>
                                <div className="text-left text-black bold-text" style={{ gridColumn: '1 / -1', backgroundColor: '#FFF4EE', border: '0.25px solid #CACACA', padding: '2px', borderRadius: '5px' }}>{date}</div> {/* Date Heading */}
                                {results.map((result, index) => (
                                    <div key={index} className={isMobile ? "text-center" : "text-left"}>
                                        <img
                                            src={result.imageURL}
                                            alt={result.patient}
                                            onClick={() => handleFullScreen(result)}
                                            style={{ width: 100, height: 100, objectFit: 'cover' }}
                                        /><br />
                                    </div>
                                ))}
                            </React.Fragment>
                        ))
                    }
                </div>
                /* <div className="text-left">
                <small>
                Name: {result.patient}<br />
                Date: {result.uploadDate}<br />
                Procedure: {result.procedure}<br />
                Type: {result.type}<br />
                </small>
                </div> */
            ) : (
                <Table>
                    <tr>
                        <th onClick={() => sortResults('imageURL')}><span type="button">Image</span></th>
                        <th onClick={() => sortResults('patient')}><span type="button">Name</span></th>
                        <th onClick={() => sortResults('uploadDate')}><span type="button">Date</span></th>
                        <th onClick={() => sortResults('procedure')}><span type="button">Procedure</span></th>
                        <th onClick={() => sortResults('type')}><span type="button">Type</span></th>
                    </tr>
                    {searchResults.map((result, index) => (
                        <tr key={index}>
                            <td><img
                                src={result.imageURL}
                                alt={result.patient}
                                onClick={() => handleFullScreen(result)}
                                style={{ width: 200, height: 200, objectFit: 'cover', borderRadius: '20px' }}
                            /></td>
                            <td>{result.patient}</td>
                            <td>{result.uploadDate}</td>
                            <td>{result.procedure}</td>
                            <td>{result.type}</td>
                        </tr>
                    ))}
                </Table>
            )}
            {showFullScreen && (
                <div
                    style={{
                        position: 'fixed', top: 0, left: 0, right: 0, bottom: 0,
                        backgroundColor: '#1C1C1C', zIndex: 9999, display: 'flex',
                        flexDirection: 'column', // Change to column for vertical stacking
                    }}
                >
                    <button
                        onClick={handleCloseFullScreen}
                        style={{
                            position: 'absolute', top: 10, left: 10, // Positioning the button
                            width: 40, height: 40, // Button size
                            borderRadius: '50%', // Making the button circular
                            display: 'flex', alignItems: 'center', justifyContent: 'center', // Centering the 'X'
                            backgroundColor: 'white', // Button background color
                            border: '1px solid black', cursor: 'pointer', // Additional styling
                            zIndex: 10 // Ensuring it's above other elements
                        }}
                    >
                        <IoCloseSharp />
                    </button>
                    <img
                        src={currentImage.imageURL}
                        style={{ width: '100%', height: '60vh', objectFit: 'contain' }}
                        alt="full-screen"
                    />
                    <div className="p-4" style={{ backgroundColor: '#FFF4EE', width: '100%', height: '40vh' }}>
                        <h4
                            className="bold-text text-black text-left text-underline"
                            onClick={() => { setSearchType('Patient Name'); setSearchTerm(currentImage.patient); setSearchResults(allResults.filter(result => result.patient === currentImage.patient)); setShowFullScreen(false); setSortInfo({ field: 'patient', direction: 'asc' }) }}
                        >
                            {currentImage.patient}
                        </h4>
                        <p className="text-left">
                            <small>Date Taken: {currentImage.uploadDate}</small>
                        </p>
                        <Form>
                            <Row>
                                <Col className="text-left">
                                    <FormGroup>
                                        <Label for="imageType">Image Type</Label>
                                        <br />
                                        <Input
                                            style={{ width: '100%' }}
                                            type="select"
                                            name="imageType"
                                            id="imageType"
                                            defaultValue={currentImage.type}
                                            onChange={(e) => setNewImageType(e.target.value)}
                                        >
                                            <option value="" disabled>Select</option>
                                            <option value="before">Before</option>
                                            <option value="after">After</option>
                                            <option value="during">During</option>
                                            <option value="progress">Progress</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col className="text-left">
                                    <FormGroup>
                                        <Label for="procedure">Procedure</Label>
                                        <Input
                                            className="text-black"
                                            type="text"
                                            name="procedure"
                                            id="procedure"
                                            defaultValue={currentImage.procedure}
                                            onChange={(e) => setNewProcedure(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup className="text-left">
                                <Label for="notes">Notes</Label>
                                <Input
                                    className="text-black"
                                    type="text"
                                    name="notes"
                                    id="notes"
                                    defaultValue={currentImage.notes}
                                    onChange={(e) => setNewNotes(e.target.value)}
                                />
                            </FormGroup>
                            <Button
                                className="rounded-card"
                                style={{
                                    background: 'linear-gradient(to right, #ff758c 0%, #ff7eb3 100%)',
                                    color: 'white', // Adjust text color if needed
                                    width: '100%'
                                    // Add more styling as needed
                                }}
                                onClick={() => { handleUpdateImage() }}>Save</Button>
                        </Form>
                    </div>
                </div>
            )}

        </div>
    );
};




function App() {
    const [screen, setScreen] = useState('home');

    const [fetchingAppointments, setFetchingAppointments] = useState(false);
    const [appointments, setAppointments] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedPatient, setSelectedPatient] = useState(null);

    const isUserOnMobile = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    };

    const isMobile = isUserOnMobile();

    useEffect(() => {
        const fetchData = async () => {
            setFetchingAppointments(true);
            const response = await axios.get('https://imac-med-spa.labthree.ai/today-appointments2');
            const data = response.data.map(appointment => ({
                patientName: appointment.patient_name,
                scheduledTime: new Date(appointment.scheduled_time).toLocaleString('en-US', { weekday: 'long', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true }),
                notes: appointment.notes,
                reason: appointment.reason,
                id: appointment.id,
            }));
            setAppointments(data);
            setFetchingAppointments(false);
        };

        fetchData();
    }, []);

    const onPatientSelect = (selectedId) => {
        setSelectedId(selectedId);
        const selectedAppointment = appointments.find(appointment => appointment.id === selectedId);
        if (selectedAppointment) {
            setSelectedPatient(selectedAppointment.patientName);
            console.log(`Selected appointment ID: ${selectedAppointment.id}`);
        }
    };

    const PatientGallery = ({ selectedPatient }) => {
        const [patientImages, setPatientImages] = useState([]);
        const [fullScreenImage, setFullScreenImage] = useState(null);
        const handleImageClick = (image) => {

            setFullScreenImage(image);
        };

        useEffect(() => {
            const q = query(collection(db, "Photos"), where("patient", "==", selectedPatient));

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const images = [];
                querySnapshot.forEach((doc) => {
                    images.push(doc.data());
                });
                setPatientImages(images);
            });

            return () => unsubscribe();
        }, [selectedPatient]);

        return (
            <div>
                {fullScreenImage ? (
                    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: '#000', zIndex: 9998 }}>
                        <img
                            src={fullScreenImage.imageURL}
                            alt={`From ${fullScreenImage.timestamp}`}
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                maxWidth: '100%',
                                maxHeight: '100%',
                                zIndex: 9999
                            }}
                            onClick={() => setFullScreenImage(null)}
                        />
                    </div>
                ) : (
                    <>
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))', // Adjust the minmax values for responsive columns
                            gap: '10px'
                        }}>
                            {patientImages.map((image, index) => (
                                <div key={index} className="text-left">
                                    <img
                                        src={image.imageURL}
                                        alt={`From ${image.timestamp}`}
                                        style={{
                                            width: '100%', // Image takes full width of the cell
                                            height: '100px', // Adjust the height as needed
                                            objectFit: 'cover' // Ensures the image covers the area, change as needed
                                        }}
                                        onClick={() => handleImageClick(image)}
                                    /><br />
                                    <div className="text-left" style={{ fontFamily: "SF Regular", letterSpacing: 'normal' }}>
                                        {/* <small>
                                            Date: <span className="font-weight-bold">{image.uploadDate}</span><br />
                                            Procedure: <span className="font-weight-bold">{image.procedure}</span><br />
                                            Type: <span className="font-weight-bold">{image.type}</span><br />
                                        </small> */}
                                        {image.tags.map((tag, index) => (
                                            <small key={index} className="bold-text text-white px-2 py-1 mx-1" style={{ backgroundColor: randomColor(), borderRadius: '20px' }}>{`#${tag} `}</small>
                                        ))}

                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
        );
    };


    const ImageUpload = ({ selectedPatient }) => {
        const [file, setFile] = useState(null);
        const [uploading, setUploading] = useState(false);
        const [previewURLs, setPreviewURLs] = useState([]);
        const [uploadSuccess, setUploadSuccess] = useState(false);
        const [imageType, setImageType] = useState("");
        const [tags, setTags] = useState([]);
        const [selectedImage, setSelectedImage] = useState(null);
        const [note, setNote] = useState("");

        const selectedAppointment = appointments.find(appointment => appointment.id === selectedId);

        const openFullscreen = (imageUrl) => {
            setSelectedImage(imageUrl);
        };

        const closeFullscreen = () => {
            setSelectedImage(null);
        };

        const handleFileChange = (e) => {
            const files = e.target.files;
            let newFiles = []; // To hold both File object and Data URL

            const readFile = (index) => {
                if (index >= files.length) {
                    // Once all files are read, update the state to include new file info
                    setPreviewURLs(prevURLs => [...prevURLs, ...newFiles]);
                    return;
                }

                const file = files[index];
                const reader = new FileReader();

                reader.onloadend = () => {
                    // Store both the File object and the Data URL
                    newFiles.push({ file: file, dataUrl: reader.result });
                    readFile(index + 1); // Read next file
                };

                reader.readAsDataURL(file);
            };

            readFile(0); // Start reading files from index 0
        };

        const handleAddPhotos = (e) => {
            const files = e.target.files;
            let newFiles = []; // To hold both File object and Data URL

            const readFile = (index) => {
                if (index >= files.length) {
                    // Once all files are read, update the state to include new file info
                    setPreviewURLs(prevURLs => [...prevURLs, ...newFiles]);
                    return;
                }

                const file = files[index];
                const reader = new FileReader();

                reader.onloadend = () => {
                    // Store both the File object and the Data URL
                    newFiles.push({ file: file, dataUrl: reader.result });
                    readFile(index + 1); // Read next file
                };

                reader.readAsDataURL(file);
            };

            readFile(0); // Start reading files from index 0
        };

        const handleTagInput = (e) => {
            if (e.key === "Enter" || e.key === " " || e.key === "Return") {
                e.preventDefault(); // prevent the default action (form submission or spacebar scrolling)
                setTags([...tags, e.target.value.trim().toLowerCase()]);
                e.target.value = "";
            }
        };

        const handleNoteInput = (event) => {
            setNote(event.target.value);
        };


        const handleUpload = async () => {
            if (previewURLs.length === 0 || !imageType) return;

            setUploading(true);

            for (const [index, fileData] of previewURLs.entries()) {
                const file = fileData.file; // Access the File object from the fileData object
                const sanitizedPatientName = selectedPatient.replace(/\s+/g, '-');
                const currentTimestamp = moment().format('YYYY-MM-DD@h:mm-A');
                const currentDate = moment().format('YYYY-MM-DD');
                const fileName = `${sanitizedPatientName}-${currentTimestamp}-${index}`; // Add index to fileName

                const storageRef = ref(storage, `images/${fileName}`);
                const uploadTask = uploadBytesResumable(storageRef, file); // Upload the File object

                try {
                    await new Promise((resolve, reject) => {
                        uploadTask.on(
                            'state_changed',
                            (snapshot) => { /* handle progress */ },
                            (error) => {
                                console.log(error);
                                reject(error);
                            },
                            async () => {
                                try {
                                    const url = await getDownloadURL(storageRef);
                                    const photoDoc = {
                                        imageURL: url,
                                        tags: tags,
                                        type: imageType,
                                        uploadDate: currentDate,
                                        patient: selectedPatient,
                                        procedure: selectedAppointment ? selectedAppointment.reason : 'unknown',
                                        notes: note
                                    };
                                    await addDoc(collection(db, "Photos"), photoDoc);
                                    resolve();
                                } catch (error) {
                                    console.log(error);
                                    reject(error);
                                }
                            }
                        );
                    });
                } catch (error) {
                    console.error("Error uploading file:", error);
                }
            }

            setUploading(false);
            setUploadSuccess(true);
        };


        return (
            <div>
                <div>
                    {previewURLs.length > 0 && (
                        <>
                            <br />
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))', // Adjust the minmax values as needed
                                gap: '10px' // Space between items
                            }}>
                                {previewURLs.map((fileObj, index) => (
                                    <img
                                        onClick={() => setSelectedImage(fileObj.dataUrl)}
                                        key={index}
                                        src={fileObj.dataUrl} // Use dataUrl for the image source
                                        alt={`Preview ${index + 1}`}
                                        style={{
                                            width: '100%', // Image takes full width of the cell
                                            height: '100px', // Adjust the height as needed
                                            objectFit: 'cover' // Ensures the image covers the area, change as needed
                                        }}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                    {selectedImage && (
                        <div onClick={closeFullscreen} style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 9999999999999
                        }}>
                            <img src={selectedImage} style={{ maxWidth: '90%', maxHeight: '90%' }} />
                        </div>
                    )}
                </div>
                <br />
                {
                    previewURLs.length > 0 ?
                        <>
                            {!isMobile && <small className="py-2 text-left">✅ Image Selected!</small>}
                        </> :
                        <>
                            <small className="py-2 text-left">Select an image to upload for this patient:</small><br />
                        </>
                }

                {
                    previewURLs.length > 0 ?
                        <>
                            <Button
                                style={{ backgroundColor: '#fff4ee', width: '80%' }}
                                className="rounded-card custom-button"
                                onClick={() => document.getElementById("hiddenAddPhotoInput").click()}
                            >
                                <BsFillCloudUploadFill /> Add more photos
                            </Button>
                        </> :
                        <>
                            <Button
                                style={{ backgroundColor: '#fff4ee', width: '80%' }}
                                className="rounded-card custom-button"
                                onClick={() => document.getElementById("hiddenChoosePhotoInput").click()}
                            >
                                <BsFillCloudUploadFill /> Select Photos
                            </Button>
                        </>
                }

                <input
                    id="hiddenChoosePhotoInput"
                    style={{ display: 'none' }}
                    type="file"
                    onChange={handleFileChange}
                    multiple
                />
                <input
                    id="hiddenAddPhotoInput"
                    style={{ display: 'none' }}
                    type="file"
                    onChange={handleAddPhotos}
                    multiple
                />

                <br />
                <br />
                {
                    imageType ?
                        <>
                            {!isMobile && <><small className="py-2 text-left">✅ Type Selected!</small><br /></>}
                        </> :
                        <>
                            <small className="py-2 text-left">Select the type of image:</small><br />
                        </>
                }
                <Input type="select" className=" text-black rounded-card my-1 mx-1" onChange={(e) => setImageType(e.target.value)}>
                    <option value="">Select Type</option>
                    <option value="before">Before</option>
                    <option value="during">During</option>
                    <option value="during">Progress</option>
                    <option value="after">After</option>
                </Input><br /><br />
                <small className="py-2 text-left">Add tags to this image:</small><br />
                <Input className="rounded-card my-1" type="text" placeholder="Add tags (space to separate)" onKeyDown={handleTagInput} />
                <div className="my-1">
                    {tags.map((tag, index) => (
                        <small key={index} className="bold-text text-white px-2 py-1 mx-1" style={{ backgroundColor: randomColor(), borderRadius: '20px' }}>{`#${tag} `}</small>
                    ))}
                </div>
                <br />
                <small className="py-2 text-left">Add a note to this image:</small><br />
                <Input
                    type="textarea"
                    value={note}
                    onChange={handleNoteInput}
                    placeholder="Enter your note here..."
                />
                {
                    imageType && (
                        <>
                            <br />
                            <Button className="rounded-card submit-button" onClick={handleUpload} disabled={uploading}>
                                {uploading ? 'Uploading...' : 'Upload'}
                            </Button>
                        </>
                    )
                }
                {uploadSuccess && <p>Upload successful!</p>}
            </div >
        );
    };
    const BulkUpload = () => {
        const [files, setFiles] = useState([]);
        const [uploading, setUploading] = useState(false);
        const [previewURLs, setPreviewURLs] = useState([]);
        const [globalUploadDate, setGlobalUploadDate] = useState(moment().format('YYYY-MM-DD'));
        const [imageTags, setImageTags] = useState({});
        const [patientName, setPatientName] = useState("");
        const [procedure, setProcedure] = useState("");

        const handleFilesChange = (e) => {
            const selectedFiles = Array.from(e.target.files);
            setFiles(selectedFiles);
            const fileReaders = selectedFiles.map((file) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                return new Promise((resolve) => {
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                });
            });

            Promise.all(fileReaders).then((urls) => {
                setPreviewURLs(urls);
            });
        };

        const handlePatientName = (e) => {
            setPatientName(e.target.value);
        };
        const handleProcedureChange = (e) => {
            setProcedure(e.target.value);
        };

        const handleTagInput = (index, e) => {
            if (e.key === " " || e.key === "Enter" || e.key === "Return") {
                setImageTags({
                    ...imageTags,
                    [index]: [...(imageTags[index] || []), e.target.value.trim().toLowerCase()],
                });
                e.target.value = "";
            }
        };

        const handleUpload = async () => {
            if (files.length === 0) return;
            setUploading(true);

            for (const [index, file] of files.entries()) {
                const currentTimestamp = moment().format('YYYY-MM-DD@h:mm-A');
                const fileName = `unknown-${currentTimestamp}-${index}`;  // Add index to fileName
                const storageRef = ref(storage, `images/${fileName}`);
                const uploadTask = uploadBytesResumable(storageRef, file);

                await new Promise((resolve, reject) => {
                    uploadTask.on(
                        'state_changed',
                        (snapshot) => { /* handle progress */ },
                        (error) => {
                            console.log(error);
                            reject(error);
                        },
                        async () => {
                            try {
                                const url = await getDownloadURL(storageRef);
                                const photoDoc = {
                                    imageURL: url,
                                    tags: imageTags[index] || [],
                                    type: 'unknown',
                                    uploadDate: globalUploadDate,
                                    patient: patientName.length > 0 ? patientName : 'unknown',
                                    procedure: procedure.length > 0 ? procedure : 'unknown',
                                };
                                await addDoc(collection(db, "Photos"), photoDoc);
                                resolve();
                            } catch (error) {
                                console.log(error);
                                reject(error);
                            }
                        }
                    );
                });
            }

            setUploading(false);
        };

        return (
            <div>
                <small>Patient Name (optional):</small>
                <Input
                    className="rounded-card text-center"
                    type="text"
                    placeholder="Enter name here"
                    value={patientName}
                    onChange={handlePatientName}
                />
                <br />
                <small>Procedure (optional):</small>
                <Input
                    className="rounded-card text-center"
                    type="text"
                    placeholder="Enter procedure here"
                    value={procedure}
                    onChange={handleProcedureChange}
                />
                <br />

                <small>Choose photos to upload:</small>
                {/* <Input
                    className="rounded-card"
                    type="file"
                    multiple
                    onChange={handleFilesChange}
                    style={{
                        cursor: 'pointer',
                        backgroundColor: '#007bff',
                        color: 'white',
                        padding: '10px',
                        borderRadius: '5px',
                        textAlign: 'center'
                    }}
                /> */}
                <br />
                <Button
                    style={{ backgroundColor: '#fff4ee', width: '80%' }}
                    className="rounded-card custom-button"
                    onClick={() => document.getElementById("hiddenFileInput").click()}
                >
                    <BsFillCloudUploadFill /> Choose Photos
                </Button>
                <input
                    id="hiddenFileInput"
                    style={{ display: 'none' }}
                    type="file"
                    multiple
                    onChange={handleFilesChange}
                />
                <br />
                {previewURLs.map((url, index) => (
                    <div key={index}>
                        <img className="m-3" src={url} alt="Preview" width={200} />
                        <Input
                            className="rounded-card"
                            type="text"
                            placeholder="Add tags (space to separate)"
                            onKeyDown={(e) => handleTagInput(index, e)}
                        />
                        <div className="my-1">
                            {imageTags[index] && imageTags[index].map((tag, i) => (
                                <span key={i} className="rounded-pill mx-2 px-2 py-1"
                                    style={{
                                        backgroundColor: '#fff4ee',
                                        border: '0.5px solid black'
                                    }}>
                                    <small>#{tag}</small>
                                </span>  // Add your own tag styling
                            ))}
                        </div>
                    </div>
                ))}
                <br />
                <small>Select a date for all of your photos:</small>
                {
                    isMobile ?
                        <>
                            {/* <Button
                                style={{ backgroundColor: '#fff4ee', width: '80%' }}
                                className="rounded-card custom-button"
                                onClick={() => {
                                    const dateInput = document.getElementById("hiddenDateInput");
                                    dateInput.style.display = 'block';
                                    dateInput.focus();
                                    dateInput.click();
                                    dateInput.style.display = 'none';
                                }}
                            >
                                {
                                    globalUploadDate ?
                                        <>
                                            <BsFillCalendarDateFill /> {moment(globalUploadDate).format("MMM D, YYYY")}
                                        </> :
                                        <>
                                            <BsFillCalendarDateFill /> Select a Date
                                        </>
                                }

                            </Button>
                            <br />
                            <input
                                id="hiddenDateInput"
                                style={{ display: 'none' }}
                                className="rounded-card"
                                type="date"
                                value={globalUploadDate}
                                onChange={(e) => setGlobalUploadDate(e.target.value)}
                            /> */}
                            <span className="ml-auto mr-auto">
                                <input
                                    style={{ backgroundColor: '#fff4ee', width: '90%' }}
                                    className="rounded-card custom-button font-weight-bold"
                                    type="date" value={globalUploadDate}
                                    onChange={(e) => setGlobalUploadDate(e.target.value)} />
                                <br />
                            </span>
                        </> :
                        <>
                            <Input
                                className="rounded-card"
                                type="date" value={globalUploadDate}
                                onChange={(e) => setGlobalUploadDate(e.target.value)} />
                        </>
                }
                <br />
                <Button className="rounded-card submit-button" onClick={handleUpload} disabled={uploading}>
                    {uploading ? 'Uploading...' : 'Upload'}
                </Button>
            </div>
        );
    };

    if (screen == 'view') {

        return (
            <>
                <Container fluid>
                    <div className="d-flex align-items-center justify-content-start" style={{ fontSize: '1.7rem', zIndex: '99999999' }}>
                        <Button className="bg-transparent no-border no-shadow" style={{ fontSize: '30px' }} onClick={() => { setScreen('home') }}>
                            <BsArrowLeft />
                        </Button>
                    </div>
                    <br />
                    <h3 className="text-center bold-text text-black">View photos</h3>
                    <p className="text-black text-center">Search for photos by patient name, tag, procedure, and more!</p>
                    <br />
                    <Card className="rounded-card">
                        <SearchImages />
                    </Card>
                </Container>
            </>
        )
    }




    if (screen == 'upload') {
        return (
            <>
                <Container fluid>
                    <div className="d-flex align-items-center justify-content-start" style={{ fontSize: '1.7rem' }}>
                        <Button className="bg-transparent no-border no-shadow" style={{ fontSize: '30px' }} onClick={() => { setScreen('home'); setSelectedPatient(null); setSelectedId(null) }}>
                            <BsArrowLeft />
                        </Button>
                    </div>
                    <br />
                    <h3 className="text-center bold-text text-black">Upload a photo</h3>
                    <br />
                    <Card className="rounded-card">
                        {
                            fetchingAppointments ?
                                <>
                                    <div className="text-center mx-auto">
                                        <Hearts
                                            height="80"
                                            width="80"
                                            color="gray"
                                            ariaLabel="hearts-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                        />
                                        <p>Loading...</p>
                                    </div>
                                </> :
                                <>
                                    {
                                        !selectedPatient ? (
                                            <small className="py-2">Select an appointment to upload an image</small>
                                        ) :
                                            <>
                                                {!isMobile && <small className="py-2 text-left">✅ Appointment Selected!</small>}
                                            </>
                                    }
                                    <Input
                                        className="text-black mx-5 rounded-card"
                                        type="select"
                                        onChange={e => { onPatientSelect(e.target.value); }}
                                    >
                                        <option value="" disabled selected>Select a Patient</option>
                                        {appointments.map((appointment, index) => (
                                            <option key={index} value={appointment.id}>
                                                {appointment.patientName} - {appointment.scheduledTime} - {appointment.reason}
                                            </option>
                                        ))}
                                    </Input>
                                </>
                        }

                        {
                            selectedPatient ?
                                <>
                                    <ImageUpload selectedPatient={selectedPatient} />
                                </> :
                                <>
                                </>
                        }
                    </Card>
                    <br />
                    {
                        selectedPatient && (
                            <>
                                <h4 className="text-black bold-text">

                                </h4>
                                <PatientGallery selectedPatient={selectedPatient} />
                            </>
                        )
                    }
                </Container>
            </>
        )
    }

    if (screen == "bulk") {
        return (
            <>
                <Container fluid>
                    <div className="d-flex align-items-center justify-content-start" style={{ fontSize: '1.7rem' }}>
                        <Button className="bg-transparent no-border no-shadow" style={{ fontSize: '30px' }} onClick={() => { setScreen('home') }}>
                            <BsArrowLeft />
                        </Button>
                    </div>
                    <br />
                    <h3 className="text-center bold-text text-black">Bulk Upload Photos</h3>
                    <p className="text-black text-center">For uploading previous images easily.</p>
                    <br />
                    <Card className="rounded-card">
                        <BulkUpload />
                    </Card>
                </Container>
            </>
        )
    }



    if (screen == 'home') {
        return (
            <Container fluid>
                <br />
                <h3 className="text-center bold-text text-black">IMAC Photos</h3>
                <br />
                {isMobile ? (
                    <div className="mobile-card-container">
                        <Card className="rounded-card">
                            <CardBody>
                                <h3 className="bold-text text-black text-center">Upload a Photo</h3>
                                <CardText>Use this section to upload before/after images for a patient or procedure.</CardText>
                                <Button onClick={() => { setScreen('upload') }} className="rounded-card" color="primary">Upload</Button>
                            </CardBody>
                        </Card>
                        <Card className="rounded-card my-3">
                            <CardBody>
                                <h3 className="bold-text text-black text-center">View Photos</h3>
                                <CardText>Find and view uploaded images based on patient name or procedure type.</CardText>
                                <Button onClick={() => { setScreen('view') }} className="rounded-card" color="primary">View</Button>
                            </CardBody>
                        </Card>
                        <Card className="rounded-card my-3">
                            <CardBody>
                                <h3 className="bold-text text-black text-center">Bulk Upload Photos</h3>
                                <CardText>Bulk upload photos from your camera roll.</CardText>
                                <Button onClick={() => { setScreen('bulk') }} className="rounded-card" color="primary">Get started</Button>
                            </CardBody>
                        </Card>
                    </div>
                ) : (
                    <>
                        <CardDeck>
                            <Card className="rounded-card">
                                <CardBody>
                                    <h3 className="bold-text text-black text-center">Upload a Photo</h3>
                                    <CardText>Use this section to upload before/after images for a patient or procedure.</CardText>
                                    <Button onClick={() => { setScreen('upload') }} className="rounded-card" color="primary">Upload</Button>
                                </CardBody>
                            </Card>
                            <Card className="rounded-card">
                                <CardBody>
                                    <h3 className="bold-text text-black text-center">View Photos</h3>
                                    <CardText>Find and view uploaded images based on patient name or procedure type.</CardText>
                                    <Button onClick={() => { setScreen('view') }} className="rounded-card" color="primary">View</Button>
                                </CardBody>
                            </Card>
                        </CardDeck>
                        <br />
                        <Card className="rounded-card">
                            <CardBody>
                                <h3 className="bold-text text-black text-center">Bulk Upload Photos</h3>
                                <CardText>Bulk upload photos from your camera roll.</CardText>
                                <Button onClick={() => { setScreen('bulk') }} className="rounded-card" color="primary">Get started</Button>
                            </CardBody>
                        </Card>
                    </>

                )}
            </Container>

        );
    }
}

export default App;

const randomColor = () => {
    const r = Math.floor(Math.random() * 128);
    const g = Math.floor(Math.random() * 128);
    const b = Math.floor(Math.random() * 128);
    return `rgb(${r}, ${g}, ${b})`;
};