import React from 'react';
import MyCGrid from './MyCGrid';  // Assuming MyGrid component is in the same directory

const CheckoutCommon = () => {
  
  return (
    <div>
      <br></br>
      <h1 className="bold-text text-black">Checkout Neurotoxins</h1>
      <MyCGrid />
    </div>
  );
};

export default CheckoutCommon;

//bottle number