import React, { useState, useEffect } from 'react';
import '../App.css';
import { Container, Row, Col, Card, CardDeck, CardBody, Nav, NavItem, NavLink, Navbar, Input, FormGroup, Label, NavbarBrand, Button, Table } from 'reactstrap';
import axios from 'axios';
import IMACPinkLogo from '../images/imac-logo-pink.png';
import moment from 'moment';
import { Hearts } from "react-loader-spinner";
import { BsFillCheckCircleFill } from 'react-icons/bs';



const GenerateCheckout = () => {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [notes, setNotes] = useState('');
  const [appointments, setAppointments] = useState([]);
  const [neurotoxinUnits, setNeurotoxinUnits] = useState('');
  const [fillerSyringes, setFillerSyringes] = useState('');
  const [selectedOptions, setSelectedOptions] = useState({});
  const [passcode, setPasscode] = useState('');
  const [hidePasscodeInput, setHidePasscodeInput] = useState(false);
  const [fetchingAppointments, setFetchingAppointments] = useState(false);
  const [recommendations, setRecommendations] = useState('');
  const [skincare, setSkincare] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [selectedId, setSelectedId] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);


  const passcodeToName = {
    '1234': 'Lab3 Test',
    '1235': 'Lab3 Test',
    '3696': 'Briana Carrera',
    '7404': 'Brianna Gusman',
    '3825': 'Candice Hannoun',
    '9149': 'Casie McGuire',
    '6305': 'Chrisian Castillo',
    '0320': 'Jasmine Beutell',
    '3505': 'Laurie Bharitkar',
    '5686': 'Michelle Rahim',
    '7199': 'Raymond Dorio',
    '0999': 'Vanessa Amande'
  };

  const passcodeToAdmin = {
    '6305': 'Christian Castillo',
    '1235': 'Lab Admin',
  };

  useEffect(() => {
    if (passcodeToName[passcode]) {
      setHidePasscodeInput(true);
    }
  }, [passcode]);
  useEffect(() => {
    if (passcodeToName[passcode]) {
      setTimestamp(passcodeToName[passcode] + ' @ ' + moment(Date.now()).format('YYYY-MM-DD hh:mm:ss A'));
    }
  }, [passcode]);

  useEffect(() => {
    const fetchData = async () => {
      setFetchingAppointments(true);
      const response = await axios.get('https://imac-med-spa.labthree.ai/today-appointments2');
      const data = response.data.map(appointment => ({
        patientName: appointment.patient_name,
        scheduledTime: new Date(appointment.scheduled_time).toLocaleString('en-US', { weekday: 'long', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true }),
        notes: appointment.notes,
        reason: appointment.reason,
        id: appointment.id,
      }));
      setAppointments(data);
      setFetchingAppointments(false);
    };

    fetchData();
  }, []);


  const onSave = async () => {
    setSaveLoading(true);
    let neurotoxinList = Object.keys(selectedOptions['Neurotoxins'] || {}).filter(k => selectedOptions['Neurotoxins'][k]).join(", ");
    let fillerList = Object.keys(selectedOptions['Fillers'] || {}).filter(k => selectedOptions['Fillers'][k]).join(", ");

    const allData = `
${appointments.find(a => a.id === selectedId)?.notes}

Additional Notes: ${additionalNotes}

Recommendations: ${recommendations}

Skincare: ${skincare}

Neurotoxin: ${neurotoxinUnits} Units
Selected Neurotoxins: ${neurotoxinList}
Filler: ${fillerSyringes} Syringes
Selected Fillers: ${fillerList}
Signed: ${timestamp}
  `;

    try {
      const response = await axios.post('https://imac-receptionist-bot.glitch.me/update_notes', {
        appointment_id: selectedId,
        note_text: allData
      });

      if (response.status === 200) {
        console.log('Data sent successfully');
      } else {
        console.log('Something went wrong:', response.status);
      }
    } catch (error) {
      console.error('Error sending data:', error);
    }
    setSaveLoading(false);
    setSaveSuccess(true)
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };




  const handleCheckboxChange = (e, category) => {
    setSelectedOptions({
      ...selectedOptions,
      [category]: { ...selectedOptions[category], [e.target.name]: e.target.checked },
    });
  };

  const onPatientSelect = (selectedId) => {
    setSelectedId(selectedId);
    const selectedAppointment = appointments.find(appointment => appointment.id === selectedId);
    if (selectedAppointment) {
      setSelectedPatient(selectedAppointment.patientName);
      console.log(`Selected appointment ID: ${selectedAppointment.id}`);
    }
  };

  return (
    <>
      <br />
      <h2 className="bold-text text-black">Patient Checkout Form</h2>
      <p className="reg-text">Fill out the required information to check out the patient from your exam room. </p>
      <Card body className="mt-4 rounded-card" style={{ backgroundColor: '#ffd6dd' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={IMACPinkLogo} alt="Logo" style={{ marginRight: '20px' }} />
          <Card className="text-center no-border bg-transparent" style={{ width: '100%' }}>
            <h3 className="bold-text" style={{ color: '#E95F5E' }}>Patient Info</h3>
            {
              fetchingAppointments ?
                <>
                  <div className="text-center mx-auto">
                    <Hearts
                      height="80"
                      width="80"
                      color="gray"
                      ariaLabel="hearts-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                    <p>Loading...</p>
                  </div>
                </> :
                <>
                  <Input
                    className="mx-5 rounded-card"
                    type="select"
                    onChange={e => { onPatientSelect(e.target.value); }}
                  >
                    <option value="" disabled selected>Select a Patient</option>
                    {appointments.map((appointment, index) => (
                      <option key={index} value={appointment.id}>
                        {appointment.patientName} - {appointment.scheduledTime} - {appointment.reason}
                      </option>
                    ))}
                  </Input>
                </>
            }


            {/* Display notes for the selected patient */}
            {selectedPatient && (
              <div className="text-left mx-5">
                {/* <h4 className="med-text text-black">{appointments.find(a => a.patientName === selectedPatient)?.patientName}</h4> */}
                <br />
                <small className="pl-2">Notes:</small>
                <Card className="rounded-card med-text" style={{ backgroundColor: '#ffedef' }}>
                  <p>{appointments.find(a => a.id === selectedId)?.notes}</p>
                </Card>

              </div>
            )}
          </Card>
        </div>
        <br />
        {
          selectedPatient && (
            <>
              <CardDeck>
                <Card className="rounded-card" style={{ backgroundColor: '#ffedef' }}>
                  <h4 className="med-text text-black">Neurotoxins</h4>
                  <Input type="number" placeholder="Units" value={neurotoxinUnits} onChange={e => setNeurotoxinUnits(e.target.value)} style={{ color: 'black' }} />

                  <FormGroup check style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', textAlign: 'left' }}>
                    {['Botox', 'Xeomin'].map(option => (
                      <Label check style={{ flex: '50%', margin: '5px 0', minWidth: '100px' }}>
                        <Input type="checkbox" name={option} onChange={e => handleCheckboxChange(e, 'Neurotoxins')} />
                        {option}
                      </Label>
                    ))}
                    {['Disport', 'Jevveau'].map(option => (
                      <Label check style={{ flex: '50%', margin: '5px 0', minWidth: '100px' }}>
                        <Input type="checkbox" name={option} onChange={e => handleCheckboxChange(e, 'Neurotoxins')} />
                        {option}
                      </Label>
                    ))}
                  </FormGroup>
                </Card>

                <Card className="rounded-card" style={{ backgroundColor: '#ffedef' }}>
                  <h4 className="med-text text-black">Fillers</h4>
                  <Input type="number" placeholder="# Syringes" value={fillerSyringes} onChange={e => setFillerSyringes(e.target.value)} style={{ color: 'black' }} />

                  <FormGroup check style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', textAlign: 'left' }}>
                    {['Cheeks', 'Smile Lines', 'Lips', 'Chin'].map(option => (
                      <Label check style={{ flex: '50%', margin: '5px 0', minWidth: '120px' }}>
                        <Input type="checkbox" name={option} onChange={e => handleCheckboxChange(e, 'Fillers')} />
                        {option}
                      </Label>
                    ))}
                    {['Jawline', 'Under Eye', 'Nose'].map(option => (
                      <Label check style={{ flex: '50%', margin: '5px 0', minWidth: '120px' }}>
                        <Input type="checkbox" name={option} onChange={e => handleCheckboxChange(e, 'Fillers')} />
                        {option}
                      </Label>
                    ))}
                  </FormGroup>
                </Card>
              </CardDeck>
              <br />
              <CardDeck>
                <Card className="rounded-card" style={{ backgroundColor: '#ffedef' }}>
                  <h4 className="med-text text-black">Recommendations</h4>
                  <Input type="textarea" className="rounded-card text-black" placeholder="Enter recommendations here..." value={recommendations} onChange={e => setRecommendations(e.target.value)} style={{ color: 'black' }} />

                </Card>
                <Card className="rounded-card" style={{ backgroundColor: '#ffedef' }}>
                  <h4 className="med-text text-black">Skincare</h4>
                  <Input type="textarea" className="rounded-card text-black" placeholder="Enter skincare details here..." value={skincare} onChange={e => setSkincare(e.target.value)} style={{ color: 'black' }} />
                </Card>
              </CardDeck>
              <br />
              <small className="med-text text-black text-left pl-2">Additional notes</small>
              <Input type="textarea" className="rounded-card text-black" placeholder="Enter additional notes here..." value={additionalNotes} onChange={e => setAdditionalNotes(e.target.value)} style={{ color: 'black' }} />

              <br />
              {
                saveLoading ?
                  <>
                    <Hearts
                      height="80"
                      width="80"
                      color="gray"
                      ariaLabel="hearts-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                    <p>Saving...</p>
                  </> :
                  <>
                    {
                      saveSuccess ?
                        <>
                          <div className="text-center">
                            <BsFillCheckCircleFill size={80} color="green" />
                            <p>Patient information saved!</p>

                          </div>
                        </>
                        :
                        <>
                          {!hidePasscodeInput && (
                            <div>
                              <small>Enter your passcode to save:</small>
                              <Input
                                autoComplete="off"
                                type="password"
                                placeholder="Enter Passcode"
                                value={passcode}
                                onChange={e => setPasscode(e.target.value)}
                                className="rounded-card"
                              />
                            </div>
                          )}
                          {hidePasscodeInput && (
                            <div>
                              {timestamp && (
                                <div className="med-text">{timestamp}</div>
                              )}
                              <button className="rounded-card px-5 med-text" onClick={onSave}>Save</button>
                            </div>
                          )}
                        </>
                    }
                  </>
              }

            </>
          )
        }

      </Card>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>

  );
};

export default GenerateCheckout;






// <div className="checkout-container">
//   <div className="patient-section">
//     <div className="patient-bubble" onClick={() => setSelectedPatient(!selectedPatient)}>
//       {selectedPatient ? 'Hide Appointments' : 'Show Appointments'}
//     </div>
//     {selectedPatient && (
//       <div className="appointment-list">
//         {todaysAppointments.map((patient, index) => (
//           <div key={index} className="appointment-item">
//             {patient.name} - {patient.dob}
//           </div>
//         ))}
//       </div>
//     )}
//   </div>
//   <br />
//   <div className="checkout-section">
//     <div className="checkout-items">
//       <h3>Checked Out Items</h3>
//       {/* Implement list of checked out items */}
//     </div>

//     <div className="billing-items">
//       <h3>Billing Items</h3>
//       {/* Implement list of billing items */}
//     </div>

//     <div className="checkout-notes">
//       <h3>Notes</h3>
//       <textarea value={notes} onChange={(e) => setNotes(e.target.value)} />
//     </div>

//     <button className="checkout-button">Send</button>
//   </div>
// </div>