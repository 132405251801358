import React, { useState, useEffect } from 'react';
import { Container, Input, Row, Col } from 'reactstrap'; // Import Row, Col and Input from 'reactstrap'

import trashImage from '../images/trash.png'; // import your trash icon
import { db } from '../firebase';
import { collection, addDoc, deleteDoc, doc, updateDoc, query, getDocs, setDoc, where, onSnapshot } from 'firebase/firestore';

const UpdateSupplies = () => {
    const [rows, setRows] = useState([]);
    const headers = ['Name', 'Price', 'Quantity', 'Vendor'];
    const [searchTerm, setSearchTerm] = useState('');


    const handleCellBlur = async (index, header, event) => {
        const newValue = event.target.value;
        const newRow = { ...rows[index], [header]: newValue };
        const newRows = [...rows];
        newRows[index] = newRow;
        setRows(newRows);

        try {
            const productDoc = doc(db, 'Inventory', 'Main Inventory', 'Products', newRow.id);
            await updateDoc(productDoc, { [header]: newValue });
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    };

    const handleCellChange = (index, header, event) => {
        const newValue = event.target.value;
        const newRow = { ...rows[index], [header]: newValue };  // removed the "N/A" fallback since we want to allow it to be empty
        const newRows = [...rows];
        newRows[index] = newRow;
        setRows(newRows);
    };
    

    const fetchMainInventoryData = async () => {
        try {
            const mainInventoryCollection = collection(db, "Inventory", "Main Inventory", "Products");
            const q = query(mainInventoryCollection);
            const querySnapshot = await getDocs(q);
            const fetchedData = [];
            querySnapshot.forEach((doc) => {
                const dataWithId = { ...doc.data(), id: doc.id };
                if (allowedProductNames.includes(dataWithId.Name)) {
                    fetchedData.push(dataWithId);
                }
            });
            setRows(fetchedData);
        } catch (error) {
            console.error("Error fetching from Firestore: ", error);
        }
    };

    useEffect(() => {
        fetchMainInventoryData();
    }, []);

    const allowedProductNames = [
        "Bath Tissue",
        "18G Needle Box of 100",
        "1ml syringe w/ luer lock",
        "Aquaphor",
        "Baby Wipes Pack of 11",
        "Cavi Wipes",
        "Coffee Cups",
        "Coffee pods",
        "Coffee Stirrers",
        "Creamer Pods",
        "Disposable Forks",
        "Disposable Headbands (48)",
        "Dr. Pen Cartridges",
        "Drapes",
        "Exam Table Paper",
        "Face Mask",
        "Febreze",
        "Gauze Pack of 10",
        "Gloves box of 100",
        "Headbands",
        "Hibiclens",
        "Honey Spoons",
        "Hydrocortisone",
        "Kleenex",
        "Micellar Water",
        "Paper Towels",
        "PDI Sani-Hands Wipes",
        "Printer Ink G6020",
        "Printer Ink iP8720",
        "Sample Jars 5g",
        "Splenda",
        "Sticky Notes",
        "Sugar Candy Sticks",
        "Sugar In The Raw",
        "Tall Trash Bags 13 gallons",
        "Tongue Depressors",
        "Trash Bags 10 gallon",
        "Ultrasound Gel",
        "IMAC Birthday Suit",
        "IMAC Exposed",
        "IMAC Glossed Up"];

    return (
        <Container style={{ padding: '20px' }}> {/* Add padding for spacing */}
            <Row className="mb-4"> {/* Add margin bottom for spacing */}
                <Col>
                    <h2 className="text-black bold-text">Supplies</h2> 
                    <p>Use this list to update your inventory. Simply change a value, that's it!</p>
                </Col>
            </Row>
            <Row className="mb-4"> {/* Add margin bottom for spacing */}
                <Col>
                    <Input className='rounded-card'
                        type="search"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Container style={{ maxWidth: '70vw', overflowY: 'auto' }} className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    {headers.map((header, index) => (
                                        <th key={index}>{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {rows.filter(row => {
                                    if (searchTerm === '') return true;
                                    const lowercaseTerm = searchTerm.toLowerCase();

                                    const matchesName = row.Name ? row.Name.toLowerCase().includes(lowercaseTerm) : false;
                                    const matchesVendor = row.Vendor ? row.Vendor.toLowerCase().includes(lowercaseTerm) : false;

                                    return matchesName || matchesVendor;
                                }).map((row, i) => (
                                    <tr key={row.id} >{/* Added styling here */}
                                        {headers.map((header, j) => (
    <td key={j}>
        <input className='rounded-card'
            type="text"
            value={row[header] !== undefined ? row[header] : "N/A"} // Display "N/A" if the value is undefined
            name={header}
            onBlur={(event) => handleCellBlur(i, header, event)}
            onChange={header === 'Quantity' ? (event) => handleCellChange(i, header, event) : null}
            readOnly={header !== 'Quantity'}
            style={header !== 'Quantity' ? { backgroundColor: '#e9ecef' } : {}}
        />
    </td>
))}

                                    </tr>
                                ))}
                                
                            </tbody>


                        </table>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

export default UpdateSupplies;