import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { useLocation } from 'react-router-dom'; // 
import { Button, Container, Card, Nav, NavItem, Navbar, NavbarBrand } from 'reactstrap';
import { InfinitySpin } from "react-loader-spinner";
import CheckoutMain from './components/checkoutmain';
import CheckoutCommon from './components/checkoutcommon';
import GenerateCheckout from './components/generatecheckout';
import UpdateSupplies from './components/updatesupplies';
import './App.css';
import logo from './images/imac-logo.png';
import ChronoLogo from './images/chrono-logo.svg';
import ReceivePOForm from './components/ReceivePOForm';
import { db, auth } from "./firebase";
import PatientStore from './PatientStore';
import { observer } from "mobx-react-lite";
import MyCGrid from './components/MyCGrid';
import SMSBlast from './components/SMSBlast';
import MyGrid from './components/MyGrid';
import Photos from './components/Photos';
import { FaBars } from 'react-icons/fa';
import Sidebar from "react-sidebar";
import axios from 'axios';



function MainContent() {
  const [showReceivePO, setShowReceivePO] = useState(true);
  const [showCheckOutMain, setShowCheckOutMain] = useState(false);
  const [showCheckOutCommon, setShowCheckOutCommon] = useState(false);
  const [showGenerateCheckout, setShowGenerateCheckout] = useState(false);
  const [showUpdateSupplies, setShowUpdateSupplies] = useState(false);
  const [role, setRole] = useState(null);

  const [authenticated, setAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputPassword, setInputPassword] = useState('');
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const inputStyle = incorrectPassword ? {
    animation: 'shake 0.2s',
    borderRadius: '10px ',
    padding: '10px',
    border: '1px solid red'
  } : {
    borderRadius: '10px ',
    border: '1px solid rgb(202, 202, 202)',
    padding: '10px'
  };
  useEffect(() => {
    const tokenExpiry = localStorage.getItem('token_expiry');
    setRole(localStorage.getItem('role'))
    if (tokenExpiry && new Date().getTime() < Number(tokenExpiry)) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }

    if (!localStorage.getItem('role')) {
      setAuthenticated(false);
    }
  }, []);

  const authenticate = async (password) => {
    setLoading(true);
    try {
      const response = await axios.post('https://imac-med-spa.labthree.ai/staff-auth', { password });
      if (response.data.authenticated) {
        const fourDaysInMilliseconds = 4 * 24 * 60 * 60 * 1000;
        localStorage.setItem('token_expiry', (new Date().getTime() + fourDaysInMilliseconds).toString());
        localStorage.setItem('role', response.data.role);
        setRole(response.data.role)
        console.log(response.data.role)
        setAuthenticated(true);
        setIncorrectPassword(false);
      } else {
        setIncorrectPassword(true);
        setShowErrorMsg(true);
        setTimeout(() => setShowErrorMsg(false), 2000);
      }
    } catch (error) {
      console.error('Auth failed:', error);
    }
    setLoading(false);
  };

  if (!authenticated) {
    return (
      <>
        <div className="App">
          <Navbar expand="md" className="App-navbar">
            <NavbarBrand href="/">
              <img src={logo} alt="logo" className="App-logo" />
            </NavbarBrand>
          </Navbar>
          {
            loading ?
              <>
                <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh', flexDirection: 'column' }}>
                  <InfinitySpin
                    type="ThreeDots"
                    color="#00BFFF"
                    height={300}
                    width={300}
                  />
                  <p>Authenticating...</p>
                </Container>
              </> :
              <>
                <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh', flexDirection: 'column' }}>
                  <div>
                    <Card className="px-8 py-5 rounded-card">
                      <h2 className="bold-text text-black">Log in</h2>
                      <p className="text-black">Please enter your password to access the dashboard.</p>

                      <div>
                        <input
                          type="password"
                          placeholder="Password"
                          value={inputPassword}
                          onChange={(e) => setInputPassword(e.target.value)}
                          style={inputStyle}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              authenticate(inputPassword);
                            }
                          }}
                        />
                        <br />
                        <br />
                        <button
                          className="rounded-card"
                          onClick={() => authenticate(inputPassword)}
                        >
                          Submit
                        </button>
                        {showErrorMsg && <><br /><br /><p style={{ color: 'red', opacity: showErrorMsg ? 1 : 0 }}>Incorrect password. Please try again.</p></>}
                        <br /><br />

                      </div>
                    </Card>
                  </div>
                  <div className="text-center">
                    <a href="https://labthree.org/insights" target="_blank"><small className="text-muted">Powered by Lab3</small></a>
                  </div>
                </Container>
              </>
          }


        </div>
      </>
    )
  }


  const sidebarContent = (
    <ul className="p-3 " style={{ listStyleType: "none", padding: 0 }}>
      {
        role === 'staff' &&
        (
          <>
            <li className="font-weight-bold my-2"><a className="text-black " href="/receive-po">Receive PO</a></li>
            <hr />
            <li className="font-weight-bold my-2"><a className="text-black " href="/checkout-main">Checkout Item</a></li>
            <hr />
            <li className="font-weight-bold my-2"><a className="text-black " href="/checkout-nurse">Checkout Neurotoxins</a></li>
            <hr />
            <li className="font-weight-bold my-2"><a className="text-black " href="/update-supplies">Update Supplies</a></li>
            <hr />
            <li className="font-weight-bold my-2"><a className="text-black " href="/">Photos</a></li>
            <hr />
          </>
        )
      }
      {
        role === 'marketing' &&
        (
          <>
            <li className="font-weight-bold my-2"><a className="text-black " href="/sms-blast">Send SMS Blasts</a></li>
            <hr />
            <li className="font-weight-bold my-2"><a className="text-black " href="/">Photos</a></li>
            <hr />
          </>
        )
      }

      <img src={logo} alt="logo" style={{ width: '100px', height: 'auto' }} />
    </ul>
  );

  // const location = useLocation(); // Get current location

  // const isPhotosPage = location.pathname === '/';


  // useEffect(() => {
  //   const intervalId = PatientStore.startFetchingPatientsHourly();
  //   return () => clearInterval(intervalId);
  // }, []);

  const isUserOnMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  };

  const isMobile = isUserOnMobile();

  const handleClick = (type) => {
    setShowReceivePO(type === 'Receive PO');
    setShowCheckOutMain(type === 'Check Out Main');
    setShowCheckOutCommon(type === 'Check Out Nurse');
    setShowGenerateCheckout(type === 'Generate Checkout');
    setShowUpdateSupplies(type === 'Update Supplies');
  };

  return (
    <>
      {
        isMobile ?
          <>
            <div style={{ backgroundColor: 'white' }} className="text-right" >
              <Sidebar
                sidebar={sidebarContent}
                open={sidebarOpen}
                onSetOpen={setSidebarOpen}
                styles={{
                  sidebar: { background: "white", width: "50%", zIndex: 1000 }, // Added zIndex here
                  overlay: { zIndex: 999 }
                }}
                pullRight
              >
                <button className=" rounded-card m-3 text-black" onClick={() => setSidebarOpen(true)}> <FaBars /></button>
              </Sidebar>
            </div>
          </>
          :
          <>
            <Navbar expand="md" className="App-navbar">
              <NavbarBrand href="/">
                <img src={logo} alt="logo" />
              </NavbarBrand>

              <Nav className="mr-auto" navbar>
                {
                  role === 'staff' &&
                  (
                    <>
                      <NavItem className="pl-4">
                        <Link className="med-text rounded-card text-black" style={{ fontSize: '0.9em' }} to="/receive-po">Receive PO</Link>
                      </NavItem>
                      <NavItem>
                        <Link className="med-text rounded-card text-black" style={{ fontSize: '0.9em' }} to="/checkout-main">Checkout Item</Link>
                      </NavItem>
                      <NavItem>
                        <Link className="med-text rounded-card text-black" style={{ fontSize: '0.9em' }} to="/checkout-nurse">Checkout Neurotoxins</Link>
                      </NavItem>
                      <NavItem>
                        <Link className="med-text rounded-card text-black" style={{ fontSize: '0.9em' }} to="/update-supplies">Update Supplies</Link>
                      </NavItem>
                      <NavItem>
                        <Link className="med-text rounded-card text-black" style={{ fontSize: '0.9em' }} to="/">Photos</Link>
                      </NavItem>
                    </>
                  )
                }
                {
                  role === 'marketing' &&
                  (
                    <>
                      <NavItem>
                        <Link className="med-text rounded-card text-black" style={{ fontSize: '0.9em' }} to="/sms-blast">Send SMS Blasts</Link>
                      </NavItem>
                      <NavItem>
                        <Link className="med-text rounded-card text-black" style={{ fontSize: '0.9em' }} to="/">Photos</Link>
                      </NavItem>
                    </>
                  )
                }

              </Nav>
              <Nav className="ml-auto" navbar>
                {
                  role === 'staff' && (
                    <>
                    <a href="https://macmedspa.drchrono.com/appointments/" target="_blank" rel="noreferrer">
                      <small>Go to </small><br />
                      <img style={{ width: '70%' }} src={ChronoLogo} alt="Chrono Logo" />
                    </a>
                    <Button className="rounded-card" onClick={()=>{localStorage.clear(); window.location.reload()}}>Logout</Button>
                    </>
                  )
                }
                {
                  role === 'marketing' && ( 
                    <>
                    <Button className="rounded-card" onClick={()=>{localStorage.clear(); window.location.reload()}}>Logout</Button>
                    </>
                  )
                }
              </Nav>
            </Navbar>
          </>
      }

      <Container>
        <Routes>
          <Route path="/generate-checkout" element={<GenerateCheckout />} />
          <Route path="/checkout-main" element={<MyGrid />} />
          <Route path="/checkout-nurse" element={<CheckoutCommon />} />
          <Route path="/update-supplies" element={<UpdateSupplies />} />
          <Route path="/receive-po" element={<ReceivePOForm poNumber={1695289549662} />} />
          <Route path="/sms-blast" element={<SMSBlast />} />
          <Route path="/" element={<Photos />} />
        </Routes>
      </Container>
    </>
  );
}

export default function App() {
  return (
    <div className="App">
      <Router>
        <MainContent />
      </Router>
    </div>
  );
}

{/* <button className="rounded-card hamburger-button" onClick={() => setShowMobileMenu(!showMobileMenu)}>
              <FaBars />
            </button>
            {showMobileMenu && (
              <div className="mobile-menu">
                <NavItem className="pl-4">
                  <Link onClick={() => { setShowMobileMenu(false) }} className="med-text text-black" style={{ fontSize: '0.9em' }} to="/receive-po">Receive PO</Link>
                </NavItem>
                <NavItem>
                  <Link onClick={() => { setShowMobileMenu(false) }} className="med-text text-black" style={{ fontSize: '0.9em' }} to="/checkout-main">Checkout Item</Link>
                </NavItem>
                <NavItem>
                  <Link onClick={() => { setShowMobileMenu(false) }} className="med-text text-black" style={{ fontSize: '0.9em' }} to="/checkout-nurse">Checkout Neurotoxins</Link>
                </NavItem>
                <NavItem>
                  <Link onClick={() => { setShowMobileMenu(false) }} className="med-text text-black" style={{ fontSize: '0.9em' }} to="/update-supplies">Update Supplies</Link>
                </NavItem>
                <NavItem>
                  <Link onClick={() => { setShowMobileMenu(false) }} className="med-text text-black" style={{ fontSize: '0.9em' }} to="/">Photos</Link>
                </NavItem>
              </div>
            )} */}