import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import trashImage from '../images/trash.png'; // import your trash icon
import { db } from '../firebase'; // Path to your firebase.js file
import { collection, addDoc, deleteDoc, doc, updateDoc, query, getDocs, setDoc, where, onSnapshot, orderBy } from 'firebase/firestore';
import PatientStore from '../PatientStore';
import { Observer } from 'mobx-react-lite';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
import moment from 'moment';
import { Card, Row, Col } from 'reactstrap';


const MyCGrid = () => {
  const [rows, setRows] = useState([]);
  const [editableRows, setEditableRows] = useState({});
  const [passcode, setPasscode] = useState('');
  const [updatedRows, setUpdatedRows] = useState({});
  const [patientsToday, setPatientsToday] = useState([]);
  const [rowIds, setRowIds] = useState([]); // 
  const [appointments, setAppointments] = useState([]);
  const [fetchingAppointments, setFetchingAppointments] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [botoxBox, setBotoxBox] = useState([]);
  const [semiBox, setSemiBox] = useState([]);
  const [selectedItems, setSelectedItems] = useState(Array(rows.length).fill(''));
  const [productsMap, setProductsMap] = useState({});
  const [user, setUser] = useState('')




  const passcodeToName = {
    '1234': 'Lab3 Test',
    '1235': 'Lab3 Test',
    '3696': 'Briana Carrera',
    '7404': 'Brianna Gusman',
    '3825': 'Candice Hannoun',
    '9149': 'Casie McGuire',
    '6305': 'Christian Castillo',
    '0320': 'Jasmine Beutell',
    '3505': 'Laurie Bharitkar',
    '5686': 'Michelle Rahim',
    '7199': 'Raymond Dorio',
    '0999': 'Vanessa Amande'
  };

  const passcodeToAdmin = {
    '6305': 'Christian Castillo',
    '1235': 'Lab Admin',
  };
  const headers = ['Date/Time', 'Name', 'Item', 'Box', 'Units', 'Patient']; // New headers


  const addRow = (name) => {
    const currentDateObj = new Date();
    const month = String(currentDateObj.getMonth() + 1).padStart(2, '0');
    const date = String(currentDateObj.getDate()).padStart(2, '0');
    const year = currentDateObj.getFullYear();
    const hours = String(currentDateObj.getHours()).padStart(2, '0');
    const minutes = String(currentDateObj.getMinutes()).padStart(2, '0');

    const currentDate = `${month}/${date}/${year} ${hours}:${minutes}`;

    const newRow = { date: currentDate, name, item: '', itemIdentifier: '', units: '', patientName: '' };
    setRows([newRow, ...rows]);

    return 0; // Return index of the newly added row
  };




  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(doc(db, 'Inventory', 'Main Inventory'), 'Main Checkout')),
      (snapshot) => {
        const data = snapshot.docs.map(doc => doc.data());

        // Sort data based on date, most recent first
        data.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB - dateA;  // Sort in descending order
        });

        const botoxBoxes = [];
        const semiglutideBoxes = [];

        data.forEach(entry => {
          if (entry.item === 'Botox' && entry.box && botoxBoxes.length < 3) {
            const boxAndLot = `${entry.box}-${entry['lot number']}`; // use 'lot number' from Firestore
            botoxBoxes.push(boxAndLot);
          } else if (entry.item === 'Jeuveau' && entry.box && semiglutideBoxes.length < 3) {
            const boxAndLot = `${entry.box}-${entry['lot number']}`; // use 'lot number' from Firestore
            semiglutideBoxes.push(boxAndLot);
          }
        });

        console.log("Botox boxes:", botoxBoxes);
        console.log("Semiglutide boxes:", semiglutideBoxes);

        setBotoxBox([...new Set(botoxBoxes)]);
        setSemiBox([...new Set(semiglutideBoxes)]);

      },
      (error) => {
        console.error("Error listening for data: ", error);
      }
    );

    // Cleanup the listener when the component is unmounted
    return () => unsubscribe && unsubscribe();
  }, []);




  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productMap = {};
        const productsCollection = collection(db, "Inventory", "Main Inventory", "Products");
        const productsSnapshot = await getDocs(productsCollection);

        console.log('Raw Products Snapshot:', productsSnapshot.docs);

        if (productsSnapshot.docs.length > 0) {
          console.log('First Product Data:', productsSnapshot.docs[0].data());
        }

        productsSnapshot.docs.forEach(doc => {
          const product = doc.data();
          console.log('Product Name:', product.Name); // Check with the updated field name

          if (product.Name) {
            if (productMap[product.Name]) {
              console.warn(`Duplicate product name detected: ${product.Name}`);
            }
            productMap[product.Name] = { id: doc.id, ...product };
          }
        });


        console.log('Products Map:', productMap);
        setProductsMap(productMap);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setFetchingAppointments(true);
      const response = await axios.get('https://imac-med-spa.labthree.ai/today-appointments2');
      const data = response.data.map(appointment => ({
        patientName: appointment.patient_name,
        scheduledTime: new Date(appointment.scheduled_time).toLocaleString('en-US', { weekday: 'long', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true }),
        notes: appointment.notes,
        reason: appointment.reason,
        id: appointment.id,
      }));
      setAppointments(data);
      setFetchingAppointments(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Reference to the 'PendingNurse' sub-collection inside 'Pending' collection
    const pendingNurseCollectionRef = collection(doc(db, 'Inventory', 'Pending'), 'PendingNurse');

    // Create a query that sorts the results by date in descending order
    const queryWithOrderBy = query(pendingNurseCollectionRef, orderBy("date", "desc"));

    // Listen to changes on that query
    const unsubscribe = onSnapshot(queryWithOrderBy,
      (snapshot) => {
        const data = snapshot.docs.map(doc => doc.data());
        console.log("Fetched data:", data);
        const ids = snapshot.docs.map(doc => doc.id);
        setRows(data);
        setRowIds(ids);
      },
      (error) => {
        console.error("Error listening for data: ", error);
      }
    );

    // Cleanup the listener when the component is unmounted
    return () => unsubscribe && unsubscribe();
  }, []);


  useEffect(() => {
    const unsubscribe = onSnapshot(query(collection(doc(db, 'Inventory', 'Pending'), 'PendingNurse')),
      (snapshot) => {
        const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));  // Include doc.id
        setRows(data);
      },
      (error) => {
        console.error("Error listening for data: ", error);
      }
    );

    // Cleanup the listener when the component is unmounted
    return () => unsubscribe && unsubscribe();
  }, []);


  const removeRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);

    // Remove from Firestore
    const rowIdToRemove = rows[index].id;  // Access ID from the row object
    if (rowIdToRemove) {
      try {
        deleteDoc(doc(db, 'Inventory', 'Pending', 'PendingNurse', rowIdToRemove));
        console.log('Document successfully deleted!');
      } catch (error) {
        console.error("Error deleting document: ", error);
      }
    }
  };


  const handleChangePasscode = (e) => {
    setPasscode(e.target.value);
  };

  const handleEnterPasscode = () => {
    const name = passcodeToName[passcode];
    if (name) {
      const newRowIdx = addRow(name); // Store index of newly added row
      setEditableRows({
        ...editableRows,
        [newRowIdx]: true  // Set the new row to be editable
      });
      setPasscode('');
      setUser(name)
    } else {
      console.log('Incorrect passcode');
    }
  };

  const sortRowsByDate = (a, b) => {
    const parseDate = (str) => {
      const [datePart, timePart] = str.split(' ');
      const [month, day, year] = datePart.split('/').map(Number);
      const adjustedStr = `${month}-${day}-${year} ${timePart}`;
      return new Date(adjustedStr);
    };

    const dateA = parseDate(a.date);
    const dateB = parseDate(b.date);

    return dateB - dateA;  // Sort in descending order so that latest is on top
  };

  const filterEmptyFields = (row) => {
    return Object.fromEntries(
      Object.entries(row).filter(([_, value]) => value !== null && value !== "")
    );
  };

  const saveToFirestore = async (row) => {
    console.log("Logging newRow before adding to Firestore:", row);
    const cleanedRow = filterEmptyFields(row);
    try {
      await addDoc(collection(doc(db, 'Inventory', 'Pending'), 'PendingNurse'), cleanedRow);
      // You can add other functions like `updateProductQuantity` here if necessary.
      console.log('Document successfully written!');
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  const handleItemChange = (index, value) => {
    const newSelectedItems = [...selectedItems];
    newSelectedItems[index] = value;
    setSelectedItems(newSelectedItems);
  };





  const toggleRowEditable = (index, event) => {
    if (editableRows[index]) {
      // Get all inputs and selects within the clicked row
      const formElements = Array.from(event.currentTarget.closest("tr").querySelectorAll("input, select"));
      const updatedData = formElements.reduce((acc, element) => {
        const { name, value } = element;
        return { ...acc, [name]: value };
      }, {});

      // Merge updated data into original row
      const newRow = { ...rows[index], ...updatedData };
      const newRows = [...rows];
      newRows[index] = newRow;

      // Save to Firestore
      saveToFirestore(newRow);

      // Update the state
      setRows(newRows);
      setUpdatedRows({
        ...updatedRows,
        [index]: updatedData
      });
    }

    // Toggle editability
    setEditableRows({
      ...editableRows,
      [index]: !editableRows[index]
    });
  };





  return (
    <div>

      <Card body style={{ border: '2px solid black', borderRadius: '15px' }}>
        {
          user ?
            <>
              <Row>
                <Col className="text-left">
                  <h3 className="text-left text-black bold-text">
                    Hello, {user}!
                  </h3>
                  <span className="text-left text-black">Please fill out the info for your patient.<br /><small>If this is not you, please tap the reload button.</small></span>
                </Col>
                <Col className="text-right">
                  <button
                    onClick={() => window.location.reload()}
                    className="text-black rounded-card med-text"
                  >
                    Reload
                  </button>
                </Col>
              </Row>

            </> :
            <>
              <Row className="align-items-center">
                <Col className="text-left">
                  <h3 className="bold-text text-black">Enter passcode</h3>
                  <span className="text-black">Enter your 4-digit passcode to check out a neurotoxin!</span>
                </Col>
                <Col className="align-items-center d-flex">
                  <input className='rounded-card'
                    type="number"
                    value={passcode}
                    onChange={handleChangePasscode}
                    style={{
                      fontSize: '1.2rem',  // Make text larger
                      height: '40px',      // Make input taller
                      borderRadius: '15px',
                      border: '2px solid',
                      padding: '5px 10px'
                    }}
                  />

                  <button className='mx-2 rounded-card med-text text-black'
                    style={{
                      fontSize: '1.2rem',  // Make text larger
                      height: '40px',      // Make button taller
                      borderRadius: '15px',
                      padding: '5px 15px',
                      cursor: 'pointer',
                    }}
                    onClick={handleEnterPasscode}
                  >
                    Enter
                  </button>
                </Col>
              </Row>
            </>
        }

      </Card>
      <br />
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
              <th style={{ width: '80px' }}></th>
            </tr>
          </thead>
          <tbody>
            {rows.sort(sortRowsByDate).map((row, i) => (
              <tr key={i} className={i % 2 === 0 ? 'even-row' : 'odd-row'}>
                {headers.map((header, j) => {
                  const rowData = updatedRows[i]
                    ? updatedRows[i][header.toLowerCase()] || row[header.toLowerCase()]
                    : row[header.toLowerCase()];
                  return (
                    <td key={j}>
                      {/* Custom rendering logic for new headers */}
                      {header.toLowerCase() === 'date/time' ? (
                        <small className="font-weight-bold text-black">
                          {moment(row['date']).format("MM/DD @ h:mm A")}
                        </small>
                      ) : editableRows[i] ? (
                        header.toLowerCase() === 'item' ? (
                          <select
                            defaultValue={rowData}
                            name={header.toLowerCase()}
                            onChange={(e) => handleItemChange(i, e.target.value)}
                          >
                            <option value="">Select</option>
                            <option value="Botox">Botox</option>
                            <option value="Jeuveau">Jeuveau</option>
                          </select>


                        ) : header.toLowerCase() === 'lot number' ||
                          header.toLowerCase() === 'units'
                          ? (
                            <input
                              type="number"
                              defaultValue={rowData}
                              name={header.toLowerCase()}
                            />

                          )

                          : header.toLowerCase() === 'box' ? (
                            <select
                              defaultValue={rowData}
                              name={header.toLowerCase()}
                            >
                              <option value="">Select Box</option>
                              {selectedItems[i] === 'Botox' && botoxBox.map((box, index) => (
                                <option key={index} value={box}>{box}</option>
                              ))}
                              {selectedItems[i] === 'Jeuveau' && semiBox.map((box, index) => (
                                <option key={index} value={box}>{box}</option>
                              ))}
                            </select>
                          )




                            : header.toLowerCase() === 'patient' ? (
                              <select
                                name="patient"  // <-- Name added
                                value={selectedPatient}
                                onChange={e => { setSelectedPatient(e.target.value); }}
                              >
                                <option value="" disabled>Select a Patient</option>
                                {appointments.map((appointment, index) => (
                                  <option key={index} value={appointment.patientName}>
                                    {appointment.patientName} - {appointment.reason}
                                  </option>
                                ))}
                              </select>

                            ) : (
                              rowData
                            )
                      ) : (
                        rowData
                      )}
                    </td>
                  );

                })}
                <td
                  style={{
                    width: '80px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <button
                    onClick={(event) => toggleRowEditable(i, event)}
                    style={{
                      border: 'none',
                      background: 'transparent',  // Optional, to ensure it has no background
                      cursor: 'pointer'  // Optional, to maintain the clickability cursor
                    }}
                  >
                    ✓
                  </button>
                  {
                    user == "Chrisian Castillo" && (
                      <img
                        src={trashImage}
                        alt="Trash Can"
                        style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                        onClick={() => removeRow(i)}
                      />
                    )
                  }

                </td>
              </tr>
            ))}


          </tbody>
        </table>
      </div>
    </div>
  );

};

export default MyCGrid;