import React, { useEffect, useState } from 'react';
import trash from '../images/trash.png';
import { doc, getDoc, collection, getDocs, setDoc, query, where, deleteDoc, addDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Row, Card, Col } from 'reactstrap';


const ITEM_DETAILS_CONFIG_MAPPINGS = {
  'Standard': [
    { key: 'upc', label: 'UPC' },
    { key: 'quantity', label: 'Quantity' },
    { key: 'expiry', label: 'Expiration Date' }
  ],
  'Simple': [
    { key: 'quantity', label: 'Quantity' }
  ],
  'Extended': [
    { key: 'upc', label: 'UPC' },
    { key: 'serialNumber', label: 'Serial Number' },
    { key: 'boxNumber', label: 'Box Number' },
    { key: 'expiry', label: 'Expiration Date' }
  ]
};

const ITEM_DETAILS_CONFIG = {};
[
  'CollaGen',
  'Cosmedix',
  'HiPhenolic',
  'L-Glutathione',
  'MitoCORE',
  'Ortho Biotic',
  'Reacted Magnesium',
  'EltaMD Foaming Facial Cleanser',
  'EltaMD Laser Enzyme Gel',
  'EltaMD Skin Recovery Light Moisturizer',
  'EltaMD Skin Recovery Night Mask',
  'EltaMD UV Clear Broad - Spectrum SPF 46',
  'IMAC Birthday Suit',
  'Supplement',
  'IMAC Exposed',
  'IMAC Glossed Up'
].forEach(item => ITEM_DETAILS_CONFIG[item] = ITEM_DETAILS_CONFIG_MAPPINGS['Standard']);
[
  'Bath Tissue',
  '18G Needle Box of 100',
  '1ml syringe w/ luer lock',
  'Aquaphor',
  'Baby Wipes Pack of 11',
  'Cavi Wipes',
  'Coffee Cups',
  'Coffee pods',
  'Coffee Stirrers',
  'Creamer Pods',
  'Disposable Forks',
  'Disposable Headbands (48)',
  'Dr. Pen Cartridges',
  'Drapes',
  'Ultrasound Gel',
  'Exam Table Paper',
  'Face Mask',
  'Febreze',
  'Gauze Pack of 10',
  'Gloves box of 100',
  'Headbands',
  'Hibiclens',
  'Honey Spoons',
  'Hydrocortisone',
  'Kleenex',
  'Micellar Water',
  'Paper Towels',
  'PDI Sani-Hands Wipes',
  'Printer Ink G6020',
  'Printer Ink iP8720',
  'Sample Jars 5g',
  'Splenda',
  'Sticky Notes',
  'Sugar Candy Sticks',
  'Sugar In The Raw',
  'Tall Trash Bags 13 gallons',
  'Tongue Depressors',
  'Trash Bags 10 gallon'
].forEach(item => ITEM_DETAILS_CONFIG[item] = ITEM_DETAILS_CONFIG_MAPPINGS['Simple']);
const list3 = [
  'Jeuveau',
  'Vi Peel',
  'RHA 2',
  'RHA 3',
  'RHA 4',
  'RHA Redensity',
  'Boletero',
  'Radiesse',
  'Xeomin',
  'Morpheus 8',
  'Prime',
  'Resurfacing',
  'B-12 10ml',
  'Glutathione 30ml',
  'IV Plenish 50ml',
  'MICC/B12',
  'Versa',
  'Hylenex',
  'Semaglutide',
  'Numbing Cream 23% Lidocaine 7% Tetracaine',
  'Juvéderm Vollure'
].forEach(item => ITEM_DETAILS_CONFIG[item] = ITEM_DETAILS_CONFIG_MAPPINGS['Extended']);

const FIELD_MAPPINGS = {
  'orderDate': 'Order Date',
  'vendor': 'Vendor',
  'poNumber': 'PO Number',
  // 'totalQuantity': 'Qty Ordered',
  'totalCost': 'Total Paid',
  // Add any other fields as needed
};

const MyOGrid = ({ headers, handlePONumberClick, rowCount, removeRow, filter }) => {

  const [editableCell, setEditableCell] = useState(null); // Store the editable cell coordinates {row, col}
  const [poList, setPoList] = useState([]);

  const handleCellClick = (row, col) => {
    setEditableCell({ row, col });
  };

  const handleCellBlur = () => {
    setEditableCell(null);
  };

  const handleDelete = async (index) => {
    const poToDelete = poList[index];
    const poDocRef = doc(db, "Inventory", "Pending", "Pending List", poToDelete.id);
    try {
        await deleteDoc(poDocRef);
        const updatedList = [...poList];
        updatedList.splice(index, 1);
        setPoList(updatedList);
    } catch (error) {
        console.error("Error deleting document:", error);
    }
 };

 const filteredData = poList.filter(item => 
  item.vendor.toLowerCase().includes(filter.toLowerCase())
);
 


  //fetch from firebase for all Pending POs
  
  useEffect(() => {
    const fetchPOList = async () => {
      const poCollectionRef = collection(db, "Inventory", "Pending", "Pending List");
      const querySnapshot = await getDocs(poCollectionRef);
  
      let dataList = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }));
  
      // Filter the list to include only items where 'completed' is true
      // dataList = dataList.filter(item => item.completed);
  
      // Parse and sort the data by orderDate
      dataList.sort((a, b) => {
        // Convert the date to a parseable format
        const dateA = new Date('20' + a.orderDate.split(', ')[1] + ' ' + a.orderDate.split(', ')[0]);
        const dateB = new Date('20' + b.orderDate.split(', ')[1] + ' ' + b.orderDate.split(', ')[0]);
        return dateB - dateA; // For descending order
      });
  
      setPoList(dataList);
    };
  
    fetchPOList();
  }, []);
  

  


  return (
    <div>
    <div className="table-responsive">
      <table className="table table-hover">
        <thead className="scrollable-thead">
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
            <th style={{ width: '50px' }}></th>
          </tr>
        </thead>
        <tbody className="scrollable-tbody">
        {filteredData.map((po, i) => (
          <tr 
            key={po.id} 
            className={`${i % 2 === 0 ? 'even-row' : 'odd-row'} ${po.completed ? 'completed-row' : ''}`}
          >
              {Object.keys(FIELD_MAPPINGS).map((fieldKey, j) => {
                const isPONumber = fieldKey === 'poNumber';
                return (
                  <td
                    key={j}
                    onClick={() => isPONumber ? handlePONumberClick(po.id) : handleCellClick(i, j)}
                  >
                    <span type="button" className={isPONumber ? 'poNumberClass' : ''}>
                      {fieldKey === 'totalCost' ? `$${parseFloat(po[fieldKey]).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : po[fieldKey]}
                    </span>
                  </td>
                );
              })}
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ReceivePOForm = ({ poNumber }) => {
  const [poData, setPoData] = useState(null);
  const [receivedQuantity, setReceivedQuantity] = useState('');
  const [isCompleted, setIsCompleted] = useState(false);
  const [poList, setPoList] = useState(null);
  const [products, setProducts] = useState([]);
  const [processedItems, setProcessedItems] = useState([]);




  // States for item details
  const [expandedItem, setExpandedItem] = useState(null);
  const [itemDetails, setItemDetails] = useState({});

  useEffect(() => {
    const fetchPOList = async () => {
      const poCollectionRef = collection(db, "Inventory", "Pending", "Pending List");
      const querySnapshot = await getDocs(poCollectionRef);
  
      let dataList = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }));
  
      // Parse and sort the data by orderDate
      dataList.sort((a, b) => {
        const dateA = new Date(a.orderDate);
        const dateB = new Date(b.orderDate);
        return dateB - dateA; // For descending order
      });
  
      setPoList(dataList);
    };
  
    fetchPOList();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
        const productsCollection = collection(db, "Inventory", "Main Inventory", "Products");
        
        const productsSnapshot = await getDocs(productsCollection);
        const productList = productsSnapshot.docs.map(doc => {
            const data = doc.data();
            return {
                id: doc.id,
                name: data.Name,       // Assuming the field is called 'name' in Firestore
                quantity: data.Quantity // Assuming the field is called 'quantity' in Firestore
            };
        });
        setProducts(productList);
    };

    fetchProducts();
}, []);


  useEffect(() => {
    const fetchExistingDetails = async () => {
      const poDocRef = doc(db, "Inventory", "Main Inventory", "CompletedPO", poNumber);
      const poDocSnapshot = await getDoc(poDocRef);

      if (poDocSnapshot.exists()) {
        setItemDetails(poDocSnapshot.data());
        // Update the isCompleted state with fetched data
        setIsCompleted(poDocSnapshot.data().completed || false);
      } else {
        console.log("No existing details found for this PO");
        setItemDetails({});  // Reset item details
        setIsCompleted(false); // Reset the isCompleted state
      }
    };

    fetchExistingDetails();
  }, [poNumber]);

  useEffect(() => {
    if (!poNumber) return;

    const fetchPOData = async () => {
      console.log("Attempting to fetch data for Document ID:", poNumber);
      const poDocRef = doc(db, "Inventory", "Pending", "Pending List", poNumber);
      const poDocSnapshot = await getDoc(poDocRef);

      if (poDocSnapshot.exists()) {
        setPoData(poDocSnapshot.data());
      } else {
        console.error("PO not found");
      }
    };

    fetchPOData();
  }, [poNumber]);

  useEffect(() => {
    if (poData && itemDetails) {
        const updatedProcessedItems = poData.items.map(item => {
            // Default to 'Simple' if item type is not one of the three specified configurations
            const itemType = ITEM_DETAILS_CONFIG[item.name] || ITEM_DETAILS_CONFIG_MAPPINGS['Simple'];
            console.log(itemType);

            let quantity;
            if(itemType === ITEM_DETAILS_CONFIG_MAPPINGS['Extended']) {
                // Assuming each row contains a 'quantity' field, count the rows for 'Extended' type items
                quantity = (itemDetails[item.id] && Array.isArray(itemDetails[item.id])) ? itemDetails[item.id].filter(detail => detail.quantity).length : 0;
            } else {
                quantity = itemDetails[item.id]?.quantity || 0;
            }

            return {
                name: item.name,
                id: item.id,
                quantity: quantity
            };
        });

        setProcessedItems(updatedProcessedItems);
        
    }
}, [poData, itemDetails]);



  
  const handleItemExpansion = (itemId) => {
    setExpandedItem(prevItemId => (prevItemId === itemId ? null : itemId));
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    const poDocRef = doc(db, "Inventory", "Main Inventory", "CompletedPO", poNumber);
  
    let consolidatedDetails = {};
  
    for (let itemId in itemDetails) {
      console.log(itemId, itemDetails[itemId]);
      consolidatedDetails[itemId] = itemDetails[itemId];
      // consolidatedDetails[itemId].poId = poNumber; // Including the PO id for cross-referencing
    }
  
    // Add the 'completed' attribute to the consolidatedDetails
    consolidatedDetails['completed'] = isCompleted;
    const poDocRefPending = doc(db, "Inventory", "Pending", "Pending List", poNumber);
    await updateDoc(poDocRefPending, { completed: isCompleted });
  
    // Save the consolidated details as a single document
    await setDoc(poDocRef, consolidatedDetails);
    alert(`Items Received!`);
    
  
    // Start of the new code to update product quantities
    for (let processedItem of processedItems) {
      console.log("Processing:", processedItem.name);
  
      let product = products.find(p => {
          console.log("Product object:", p);
  
          const productName = p.name ? p.name.trim().toLowerCase() : "";
          const processedItemName = processedItem.name ? processedItem.name.trim().toLowerCase() : "";
  
          return productName === processedItemName;
      });
        
      if (product) {
        console.log("Matched product:", product.name, "with ID:", product.id);
        console.log("Matched product object:", product);
        console.log("First few products:", products.slice(0, 3));
        console.log("First few processedItems:", processedItems.slice(0, 3));
        

          // Get quantity from processedItem. Ensure it's a number.
          let processedItemQuantity = Number(processedItem.quantity || itemDetails[processedItem.id]['quantity-0'] || 0);
          
          // Ensure product.quantity is a number
          let productQuantity = Number(product.quantity || 0);
  
          // Update the quantity
          const q = productQuantity + processedItemQuantity;
  
          const productRef = doc(db, "Inventory", "Main Inventory", "Products", product.id);
          try {
            await updateDoc(productRef, { Quantity: q });
            console.log(`Updated product ${product.id} with new quantity: ${q}`);
        } catch (error) {
          console.warn("No match found for:", processedItem.name);
        }
        
  
      } else {
          // console.error(`Product with name ${processedItem.name} not found in products list.`);
          console.log(processedItems);
          console.log(products);
      }
  }
  
    // End of the new code to update product quantities
};

  

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  
  //   const poDocRef = doc(db, "Inventory", "Main Inventory", "CompletedPO", poNumber);
  
  //   let consolidatedDetails = {};
  
  //   for (let itemId in itemDetails) {
  //     console.log(itemId, itemDetails[itemId]);
  //     consolidatedDetails[itemId] = itemDetails[itemId];
  //     // consolidatedDetails[itemId].poId = poNumber; // Including the PO id for cross-referencing
  //   }
  
  //   // Add the 'completed' attribute to the consolidatedDetails
  //   consolidatedDetails['completed'] = isCompleted;
  
  //   // Save the consolidated details as a single document
  //   await setDoc(poDocRef, consolidatedDetails);
  //   console.log(`Saved item details for PO Document ID ${poNumber}`);

    
  
  //   // Start of the new code to update product quantities
  //   for (let processedItem of processedItems) {
  //     // Find the product from products list
  //     let product = products.find(p => p.name === processedItem.name);
    
  //     if (product) {
  //       // Update the quantity
  //       product.quantity += processedItem.quantity;

    
  //       const productsCollection = collection(db, "Inventory", "Main Inventory", "Products");
  //       const q = query(productsCollection, where("Name", "==", product.name));
        
        
  //       const querySnapshot = await getDocs(q);
  //       if (querySnapshot.size === 0) {
  //           console.error(`No products found with name: ${product.name}`);
  //       } else if (querySnapshot.size > 1) {
  //           console.warn(`Multiple products found with name: ${product.name}. Updating all.`);
  //       }
        
  //       querySnapshot.forEach((doc) => {
  //           updateDoc(doc.ref, { quantity: product.quantity });
  //           console.log(`Updated product ${doc.id} with new quantity: ${product.quantity}`);
  //       });
        
  //     } else {
  //       console.error(`Product with name ${processedItem.name} not found in products list.`);
  //     }
  //   }
  //   // End of the new code to update product quantities
  // };
  

  if (!poData) return <div>Loading...</div>;

  return (
    <form className="receive-po-form rounded-card" onSubmit={handleSubmit} style={{ backgroundColor: '#FFF0F5' }}>

      <div>
        <Row>
          <Col className="text-left" style={{ fontSize: '0.9em' }}>
            <span className="font-weight-bold">Vendor: </span> {poData.vendor}<br />
            <span className="font-weight-bold">Order Date: </span>{poData.orderDate}<br />
            <span className="font-weight-bold">Payment: </span>{`$${parseFloat(poData.totalCost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}<br />
            <span className="font-weight-bold">Total Item Count: </span>{poData.totalQuantity}<br />
          </Col>
          <Col className="text-right">
            <small>Purchase Order</small>
            <h2 className="form-header text-right">{poData.poNumber}</h2>
          </Col>
        </Row>
      </div>

      {poData.items && poData.items.filter(item => item).map((item, index) => (

        <div key={index} className="item-section">
          <div className="item-header" onClick={() => handleItemExpansion(item.id)}>
            <span>{item.name}</span>
            <span>{expandedItem === item.id ? "▲" : "▼"}</span>
          </div>
          {expandedItem === item.id && (
            <table className="item-details-table">
             <thead>
  <tr>
    {(ITEM_DETAILS_CONFIG[item.name] || ITEM_DETAILS_CONFIG_MAPPINGS['Simple']).map((detailConfig) => (
      <th key={detailConfig.key}>{detailConfig.label}</th>
    ))}
  </tr>
</thead>

              <tbody>
        {/* Check if the item is mapped to the 'Simple' configuration. If so, render only one row. */}
        {(!ITEM_DETAILS_CONFIG[item.name] || ITEM_DETAILS_CONFIG[item.name] === ITEM_DETAILS_CONFIG_MAPPINGS['Simple'] || ITEM_DETAILS_CONFIG[item.name] === ITEM_DETAILS_CONFIG_MAPPINGS['Standard']) ? (
  <tr>
    {(ITEM_DETAILS_CONFIG[item.name] || ITEM_DETAILS_CONFIG_MAPPINGS['Simple']).map((detailConfig, detailIdx) => (
      <td key={detailIdx}>
        <input 
          type="text"
          value={itemDetails[item.id]?.[`${detailConfig.key}-0`] || ""}
          onChange={e => setItemDetails({
            ...itemDetails,
            [item.id]: {
              ...itemDetails[item.id],
              [`${detailConfig.key}-0`]: e.target.value
            }
          })}
        />
      </td>
    ))}
  </tr>
) : (
          // If not 'Simple', render rows based on the item quantity.
          Array(item.quantity).fill().map((_, idx) => (
            <tr key={idx}>
              {ITEM_DETAILS_CONFIG[item.name]?.map((detailConfig, detailIdx) => (
                <td key={detailIdx}>
                  <input type="text"
                    value={itemDetails[item.id]?.[`${detailConfig.key}-${idx}`] || ""}
                    onChange={e => setItemDetails({
                      ...itemDetails,
                      [item.id]: {
                        ...itemDetails[item.id],
                        [`${detailConfig.key}-${idx}`]: e.target.value
                      }
                    })}
                  />
                </td>
              ))}
            </tr>
          ))
        )}
      </tbody>
            </table>
          )}
        </div>
      ))}
      <br />
      <div className="form-actions">
        <label>
          {'Mark as completed: '}
          <input
            type="checkbox"
            checked={isCompleted}
            onChange={() => setIsCompleted(!isCompleted)}
          />
        </label>
        <br />
        <button className="rounded-card" type="submit">Submit</button>
      </div>

    </form>
  );
};


function POManagement() {
  const [selectedPONumber, setSelectedPONumber] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const handlePONumberClick = (poNumber) => {
    console.log(`PO Number ${poNumber} clicked`);
    setSelectedPONumber(poNumber);
    console.log("Updated selectedPONumber:", selectedPONumber);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };



  return (
    <div>
      <br />
      <div>
        <h1 className="text-center text-black bold-text">
          Receive PO
        </h1>
        <p className="med-text text-black">Use this page to receive Purchase Orders by clicking on a 'PO Number'.</p>
        <br />
      </div>
      <div style={{ margin: "20px 0" }}>
        {selectedPONumber && <ReceivePOForm poNumber={selectedPONumber} />}
      </div>

      <div style={{ margin: "20px 0" }}>
        <input className='rounded-card'
          type="text"
          placeholder="Search..."
          value={searchValue}
          onChange={handleSearchChange}
          style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
        />
      </div>

      <div style={{ margin: "20px 0" }}>
      <MyOGrid headers={Object.values(FIELD_MAPPINGS)} handlePONumberClick={handlePONumberClick} filter={searchValue} />
      </div>
    </div>
  );
}


export default POManagement;