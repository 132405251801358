import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardDeck, CardBody, Nav, NavItem, NavLink, Navbar, Input, FormGroup, Label, NavbarBrand, Button, Table } from 'reactstrap';
import Select from 'react-select';
import trashImage from '../images/trash.png'; // import your trash icon
import { db } from '../firebase'; // Path to your firebase.js file
import { collection, addDoc, deleteDoc, doc, updateDoc, query, getDocs, setDoc, where, onSnapshot, orderBy } from 'firebase/firestore';
import PatientStore from '../PatientStore';
import { Observer } from 'mobx-react-lite';
import { observer } from 'mobx-react-lite';
import axios from 'axios';


const MyGrid = () => {
  const [rows, setRows] = useState([]);
  const [rowIds, setRowIds] = useState([]);
  const [editableRows, setEditableRows] = useState({});
  const [passcode, setPasscode] = useState('');
  const [updatedRows, setUpdatedRows] = useState({});
  const [patientsToday, setPatientsToday] = useState([]);
  const [productsMap, setProductsMap] = useState({});
  const [products, setProducts] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [fetchingAppointments, setFetchingAppointments] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [user, setUser] = useState('')


  const passcodeToName = {
    '1234': 'Lab3 Test',
    '1235': 'Lab3 Test',
    '3696': 'Briana Carrera',
    '7404': 'Brianna Gusman',
    '3825': 'Candice Hannoun',
    '9149': 'Casie McGuire',
    '6305': 'Christian Castillo',
    '0320': 'Jasmine Beutell',
    '3505': 'Laurie Bharitkar',
    '5686': 'Michelle Rahim',
    '7199': 'Raymond Dorio',
    '0999': 'Vanessa Amande'
  };

  const passcodeToAdmin = {
    '6305': 'Christian Castillo',
    '1235': 'Lab Admin',
  };




  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        collection(doc(db, 'Inventory', 'Main Inventory'), 'Main Checkout'),
        orderBy('date', 'desc')  // ordering by the date field in descending order
      ),
      (snapshot) => {
        const data = snapshot.docs.map(doc => doc.data());
        console.log("Fetched data:", data);
        setRows(data);
      },
      (error) => {
        console.error("Error listening for data: ", error);
      }
    );

    // Cleanup the listener when the component is unmounted
    return () => unsubscribe && unsubscribe();
  }, []);


  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        collection(doc(db, 'Inventory', 'Main Inventory'), 'Main Checkout'),
        orderBy('date', 'desc')  // ordering by the date field in descending order
      ),
      (snapshot) => {
        const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })); // Include doc.id
        setRows(data);
      },
      (error) => {
        console.error("Error listening for data: ", error);
      }
    );

    // Cleanup the listener when the component is unmounted
    return () => unsubscribe && unsubscribe();
  }, []);




  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productMap = {};
        const productsCollection = collection(db, "Inventory", "Main Inventory", "Products");
        const productsSnapshot = await getDocs(productsCollection);

        console.log('Raw Products Snapshot:', productsSnapshot.docs);

        if (productsSnapshot.docs.length > 0) {
          console.log('First Product Data:', productsSnapshot.docs[0].data());
        }

        const allowedProductNames = [
          "Jeuveau",
          "Vi Peel",
          "RHA 2",
          "RHA 3",
          "RHA 4",
          "RHA Redensity",
          "Boletero",
          "Radiesse",
          "Xeomin",
          "Morpheus 8",
          "Prime",
          "B-12 10ml",
          "Glutathione 30ml",
          "IV Plenish 50ml",
          "MICC/B12",
          "Versa",
          "Semaglutide",
          "Hylenex Box of 4",
          "Numbing Cream 23% Lidocaine 7% Tetracaine",
          "Juvéderm Vollure"
        ];

        productsSnapshot.docs.forEach(doc => {
          const product = doc.data();
          console.log('Product Name:', product.Name); // Check with the updated field name

          if (product.Name && allowedProductNames.includes(product.Name)) {
            if (productMap[product.Name]) {
              console.warn(`Duplicate product name detected: ${product.Name}`);
            }
            productMap[product.Name] = { id: doc.id, ...product };
          }
        });

        console.log('Products Map:', productMap);
        setProductsMap(productMap);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();

  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setFetchingAppointments(true);
      const response = await axios.get('https://imac-med-spa.labthree.ai/today-appointments2');
      const data = response.data.map(appointment => ({
        patientName: appointment.patient_name,
        scheduledTime: new Date(appointment.scheduled_time).toLocaleString('en-US', { weekday: 'long', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true }),
        notes: appointment.notes,
        reason: appointment.reason,
        id: appointment.id,
      }));
      setAppointments(data);
      console.log("djflsdfkd", data)
      setFetchingAppointments(false);
    };

    fetchData();
  }, []);

  const updateProductQuantity = async (productName, checkoutQty) => {
    // Guard clause to ensure productName is not undefined
    if (!productName) {
      console.error("productName is undefined. Exiting updateProductQuantity.");
      return;
    }

    checkoutQty = parseInt(checkoutQty.trim(), 10); // Convert string qty to integer after trimming spaces

    // Check if the product exists in the products map
    const product = productsMap[productName];
    if (!product) {
      console.error("Product not found:", productName);
      return;
    }

    const currentQty = product.Quantity; // Use the correct attribute (assuming it's 'Quantity' based on later code)
    const newQty = currentQty - checkoutQty;

    // Update the product's quantity in Firestore
    const productRef = doc(db, "Inventory", "Main Inventory", "Products", product.id);
    await updateDoc(productRef, { Quantity: newQty });
  }

  const filterEmptyFields = (obj) => {
    const cleanObject = {};
    for (const key in obj) {
      if (obj[key] !== undefined && obj[key] !== null && obj[key] !== "") {
        cleanObject[key] = obj[key];
      }
    }
    return cleanObject;
  };

  const handleSaveRow = async (index) => {
    const rowData = rows[index];



    // Other code for saving row or whatever you want
  }


  const addRow = (name) => {
    const currentDateObj = new Date();

    const month = String(currentDateObj.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based and pad with zero
    const date = String(currentDateObj.getDate()).padStart(2, '0');
    const year = currentDateObj.getFullYear();

    const hours = String(currentDateObj.getHours()).padStart(2, '0');
    const minutes = String(currentDateObj.getMinutes()).padStart(2, '0');

    const currentDate = `${month}/${date}/${year} ${hours}:${minutes}`;

    const newRow = { date: currentDate, name, item: '', patient: '', qty: '', id: '', lot: '', location: 'Storage' };
    setRows([newRow, ...rows]);

    return 0; // Return index of newly added row
  };

  const removeRow = async (index) => {
    const isConfirmed = window.confirm('Do you really want to delete this entry?');

    if (isConfirmed) {
      const enteredPasscode = prompt('Enter Admin Passcode:');

      if (passcodeToAdmin[enteredPasscode]) {
        await removeRowFromFirestore(rows[index].id); // Access ID from the row object
        setRows(prevRows => prevRows.filter((_, i) => i !== index));
      } else {
        alert('Incorrect Admin Passcode!');
      }
    }
  };

  const sortRowsByDate = (a, b) => {
    const parseDate = (str) => {
      const [datePart, timePart] = str.split(' ');
      const [month, day, year] = datePart.split('/').map(Number);
      const adjustedStr = `${month}-${day}-${year} ${timePart}`;
      return new Date(adjustedStr);
    };

    const dateA = parseDate(a.date);
    const dateB = parseDate(b.date);

    return dateB - dateA;  // Sort in descending order so that latest is on top
  };

  const handleChangePasscode = (e) => {
    setPasscode(e.target.value);
  };

  const handleEnterPasscode = () => {
    const name = passcodeToName[passcode];
    if (name) {
      const newRowIdx = addRow(name); // Store index of newly added row
      setEditableRows({
        ...editableRows,
        [newRowIdx]: true  // Set the new row to be editable
      });
      setPasscode('');
      setUser(name)
    } else {
      console.log('Incorrect passcode');
    }
  };

  const removeRowFromFirestore = async (docId) => {
    try {
      const docRef = doc(db, 'Inventory', 'Main Inventory', 'Main Checkout', docId);
      await deleteDoc(docRef);
      console.log('Document successfully deleted!');
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      width: '200px',   // Adjust width as required
      minHeight: '40px' // Adjust height if necessary
    })
  };

  const toggleRowEditable = (index, event) => {
    if (editableRows[index]) {
      const formElements = Array.from(event.currentTarget.closest("tr").querySelectorAll("input, select"));
      const updatedData = formElements.reduce((acc, element) => {
        const { name, value } = element;
        return { ...acc, [name]: value };

        updatedData.patient = selectedPatient;

        if (name === 'patient') {
        }

        return { ...acc, [name]: value };
      }, {});

      const newRow = { ...rows[index], ...updatedData };
      const newRows = [...rows];
      newRows[index] = newRow;

      setRows(newRows);
      setUpdatedRows({
        ...updatedRows,
        [index]: updatedData
      });

      console.log("Logging newRow before adding to Firestore:", newRow);
      const cleanedRow = filterEmptyFields(newRow);
      console.log("Sending this data to Firestore:", cleanedRow);
      addDoc(collection(doc(db, 'Inventory', 'Main Inventory'), 'Main Checkout'), cleanedRow)
        .then(() => {
          updateProductQuantity(updatedData.item, updatedData.quantity);
          console.log('Document successfully written!');
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });


    }





    // Toggle editability
    setEditableRows({
      ...editableRows,
      [index]: !editableRows[index]
    });
  };




  const headers = ['Date/Time', 'Name', 'Item', 'Patient', 'Quantity', 'Serial', 'Box', 'Lot Number', 'Location']; // Hard-coded headers


  console.log(headers);

  return (
    <div>
      <br></br>
      {/* <h1>Checkout Item</h1>
      <div>
        Enter 4-Digit Passcode:
        <br></br>

        <input className='rounded-card'
          type="text"
          value={passcode}
          onChange={handleChangePasscode}
          style={{
            borderRadius: '15px',
            border: '2px solid',  // Optional, to give it a border if it doesn't have one
            padding: '5px 10px'   // Optional, to give it some internal spacing
          }}
        />
        <br></br>
        <br></br>

        <button className='rounded-card'
          style={{
            backgroundColor: 'transparent',
            // border: '2px solid',
            borderRadius: '15px',
            padding: '5px 15px',
            cursor: 'pointer',
            transition: 'all 0.3s',
            outline: 'none'
          }}
          onClick={handleEnterPasscode}
          onMouseOver={e => e.target.style.opacity = 0.7}
          onMouseOut={e => e.target.style.opacity = 1}
        >
          Enter
        </button>
      </div> */}
      <h1 className="bold-text text-black">Checkout Item</h1>
      <Card body style={{ border: '2px solid black', borderRadius: '15px' }}>
        {
          user ?
            <>
              <Row>
                <Col className="text-left">
                  <h3 className="text-left text-black bold-text">
                    Hello, {user}!
                  </h3>
                  <span className="text-left text-black">Please fill out the info for your patient.<br /><small>If this is not you, please tap the reload button.</small></span>
                </Col>
                <Col className="text-right">
                  <button
                    onClick={() => window.location.reload()}
                    className="text-black rounded-card med-text"
                  >
                    Reload
                  </button>
                </Col>
              </Row>

            </> :
            <>
              <Row className="align-items-center">
                <Col className="text-left">
                  <h3 className="bold-text text-black">Enter passcode</h3>
                  <span className="text-black">Enter your 4-digit passcode to check out a neurotoxin!</span>
                </Col>
                <Col className="align-items-center d-flex">
                  <input className='rounded-card'
                    type="number"
                    value={passcode}
                    onChange={handleChangePasscode}
                    style={{
                      fontSize: '1.2rem',  // Make text larger
                      height: '40px',      // Make input taller
                      borderRadius: '15px',
                      border: '2px solid',
                      padding: '5px 10px'
                    }}
                  />

                  <button className='mx-2 text-black rounded-card med-text'
                    style={{
                      fontSize: '1.2rem',  // Make text larger
                      height: '40px',      // Make button taller
                      borderRadius: '15px',
                      padding: '5px 15px',
                      cursor: 'pointer',
                    }}
                    onClick={handleEnterPasscode}
                  >
                    Enter
                  </button>
                </Col>
              </Row>
            </>
        }
      </Card>
      <br />
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
              <th style={{ width: '80px' }}></th>
            </tr>
          </thead>
          <tbody>
            {rows.sort(sortRowsByDate).map((row, i) => (

              <tr key={i} className={i % 2 === 0 ? 'even-row' : 'odd-row'}>                {headers.map((header, j) => {

                console.log("Rendering Row Data:", row);
                console.log("Rendering PatientName:", row.patientName);

                const handleItemChange = (event) => {
                  const newUpdatedRows = { ...updatedRows };
                  if (!newUpdatedRows[i]) newUpdatedRows[i] = {};
                  newUpdatedRows[i].item = event.target.value;
                  setUpdatedRows(newUpdatedRows);
                };

                const handlePatientChange = (event) => {
                  const newUpdatedRows = { ...updatedRows };
                  if (!newUpdatedRows[i]) newUpdatedRows[i] = {};

                  newUpdatedRows[i].patientName = event.target.value;
                  console.log("Changed PatientName to:", event.target.value);

                  setUpdatedRows(newUpdatedRows);
                };

                const rowData = updatedRows[i]
                  ? updatedRows[i][header.toLowerCase()] || row[header.toLowerCase()]
                  : row[header.toLowerCase()];


                return (
                  <td key={j}>
                    {





                      header.toLowerCase() === 'date/time' ? (
                        row['date']
                      )
                        : editableRows[i] ? (
                          header.toLowerCase() === 'item' ? (  // <-- This is the section for 'Item'
                            <select defaultValue={rowData} name={header.toLowerCase()}>
                              <option value="">Select</option>
                              {Object.values(productsMap).map(product => (
                                <option key={product.id} value={product.Name}>
                                  {product.Name}
                                </option>
                              ))}
                            </select>
                          )

                            : header.toLowerCase() === 'quantity' ||
                              header.toLowerCase() === 'box' ||   // <-- Here's the addition for 'Box'
                              header.toLowerCase() === 'lot number' ||
                              // header.toLowerCase() === 'patient' ||
                              header.toLowerCase() === 'serial' ? (
                              <input
                                type="text"
                                defaultValue={rowData}
                                name={header.toLowerCase()}
                              />
                            )

                              : header.toLowerCase() === 'patient' ? (
                                <select
                                  name="patient"  // <-- Add this
                                  value={selectedPatient}
                                  onChange={e => { setSelectedPatient(e.target.value); }}
                                >
                                  <option value="" disabled>Select a Patient</option>
                                  {appointments.map((appointment, index) => (
                                    <option key={index} value={appointment.patientName}>
                                      {appointment.patientName} - {appointment.reason}
                                    </option>
                                  ))}
                                </select>
                              )


                                : header.toLowerCase() === 'location' ? (
                                  <select
                                    defaultValue={rowData}
                                    name={header.toLowerCase()}
                                  >
                                    <option value="Office(Fridge)">Office(Fridge)</option>
                                    <option value="Office(Cabinet)">Office(Cabinet)</option>
                                    <option value="Back (Fridge)">Back Fridge</option>
                                  </select>
                                ) : (
                                  rowData
                                )
                        ) : (
                          rowData
                        )







                    }
                  </td>
                );
              })}
                <td
                  style={{
                    width: '80px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {editableRows[i] && (
                    <button
                      style={{ border: 'none', backgroundColor: 'transparent' }}
                      onClick={(event) => {
                        handleSaveRow(i);
                        toggleRowEditable(i, event);
                      }}>
                      ✓
                    </button>

                  )}


                  <img
                    src={trashImage}
                    alt="Trash Can"
                    style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                    onClick={() => removeRow(i)}
                  />
                </td>
              </tr>
            ))}


          </tbody>
        </table>
      </div>
    </div>
  );

};

// export default MyGrid;
export default observer(MyGrid);


