import React, { useState, useEffect, useRef } from 'react';
import { Container, Table, Row, Col, FormGroup, Label, Card, Modal, ModalHeader, ModalBody, CardDeck, CardBody, Nav, NavItem, NavLink, Navbar, Input, NavbarBrand, Button } from 'reactstrap';
import '../App.css';
import greenBot from '../images/green-bot.png'
import { FaPencilAlt } from 'react-icons/fa';
import { IoAddCircle } from 'react-icons/io5';
import axios from 'axios';
import { db } from '../firebase'; // Path to your firebase.js file
import { collection, onSnapshot, getDocs, getDoc, query, doc, setDoc, updateDoc } from 'firebase/firestore';
import { InfinitySpin } from "react-loader-spinner";
import moment from 'moment';
import { SegmentedMessage } from 'sms-segments-calculator';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";


function SMSBlast() {
    const [isPopupOpen, setPopupOpen] = useState(false);

    const openPopup = () => setPopupOpen(true);
    const closePopup = () => setPopupOpen(false);


    const isUserOnMobile = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    };

    const isMobile = isUserOnMobile();


    function SimpleCard({ id, running, title, description, children, open }) {
        const [expanded, setExpanded] = useState(open); // State to toggle the description
        const [jobActive, setJobActive] = useState(true);
        const jobRef = doc(db, 'Jobs', 'Marketing');

        useEffect(() => {
            const fetchData = async () => {
                const docSnap = await getDoc(jobRef);
                if (docSnap.exists()) {
                    setJobActive(docSnap.data()[`job_${id}`]);
                }
            };
            fetchData();
        }, []);


        const toggleJob = async () => {
            await updateDoc(jobRef, { [`job_${id}`]: !jobActive });
            setJobActive(!jobActive);
        };


        return (
            <>
                <Card body className="rounded-card">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <div style={{ left: '10px', display: 'flex', alignItems: 'center' }}>
                                {
                                    id == "" ?
                                        <>
                                        </> :
                                        <>
                                            {
                                                jobActive ?
                                                    <>
                                                        <div style={{ width: '10px', height: '10px', backgroundColor: '#90EE90', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                                                        <span style={{ fontSize: '15px', marginLeft: '5px' }}>{running}</span>
                                                    </> :
                                                    <>
                                                        <div style={{ width: '10px', height: '10px', backgroundColor: 'grey', borderRadius: '50%', animation: 'pulsate 2s infinite' }}></div>
                                                        <span style={{ fontSize: '15px', marginLeft: '5px' }}>Inactive</span>
                                                    </>
                                            }
                                        </>
                                }

                            </div>
                            <h5 type="button" onClick={() => { setExpanded(!expanded) }} className="bold-text text-black">{title}</h5>
                            <p className="">{description}</p>
                        </div>
                    </div>
                    <div>
                        {expanded && children}
                    </div>
                </Card >
            </>
        )
    }


    // imgbb API KEY e0788f31f675c76d6e18e5d7e8909f7a
    function OneOffSMS() {
        const [smsText, setSmsText] = useState('');
        const [segments, setSegments] = useState(0);
        const [imageUrl, setImageUrl] = useState('');
        const [loading, setLoading] = useState(false)
        const [messageSending, setMessageSending] = useState(false)
        const [imageLoading, setImageLoading] = useState(false)
        const [contactCount, setContactCount] = useState(0);
        const [previewMode, setPreviewMode] = useState(false);

        useEffect(() => {
            const fetchContacts = async () => {
                try {
                    const response = await axios.get('https://imac-med-spa.labthree.ai/get-contacts');
                    setContactCount(response.data.length - 500);
                } catch (error) {
                    console.log('Error fetching contacts:', error);
                }
            };
            fetchContacts();
        }, []);

        let estCost = smsText.length == 0 ? '$0.00' : imageUrl.length > 0 ? '$' + Number(0.05 * contactCount).toFixed(2) : '$' + Number(segments * 0.012 * contactCount).toFixed(2)
        let simpleTextingCost = smsText.length == 0 ? '$0.00' : imageUrl.length > 0 ? '$' + Number(0.09 * contactCount).toFixed(2) : '$' + Number(Math.ceil(smsText.length / 160) * 0.04 * contactCount).toFixed(2)
        const sendSMS = async () => {
            setLoading(true);

            const confirmation = prompt("🚨WARNING:🚨\n This will send SMS to thousands of people. \n\nAre you sure you know what you're doing? Make sure you've double-checked your design and proofread the message that is being sent. \n\n Type 'agree' to continue.");

            if (confirmation !== "agree") {
                setLoading(false);
                return;
            }
            const stopToStop = '\nText STOP to unsubscribe.'

            try {
                // await axios.post('http://localhost:3000/job-95', {
                await axios.post('https://imac-med-spa.labthree.ai/job-95', {
                    // await axios.post('https://imac-med-spa.labthree.ai/testing', {
                    sms: smsText + stopToStop,
                    imageUrl: imageUrl // Add imageUrl to the request
                }, {
                    headers: { 'Content-Type': 'application/json' },
                });
                alert("SMS sent!");
                setLoading(false);
                setMessageSending(true);
            } catch (err) {
                console.error('Failed to send SMS:', err);
                setLoading(false);
            }
        };

        const handleImageUpload = async (e) => {
            setImageLoading(true);
            const file = e.target.files[0];

            // Get a reference to the storage service and create a reference to the file location
            const storage = getStorage();
            const storageRef = ref(storage, 'sms_temp_images/' + file.name);

            try {
                // Upload the file to Firebase Storage
                const snapshot = await uploadBytes(storageRef, file);

                // Get the URL of the uploaded file
                const url = await getDownloadURL(snapshot.ref);
                setImageUrl(url);
            } catch (error) {
                console.error("Error uploading image: ", error);
            }

            setImageLoading(false);
        };

        const handleMessageChange = (event) => {
            const newText = event.target.value;
            setSmsText(newText); // Update the state with the new text
            const segmentedMessage = new SegmentedMessage(newText);
            setSegments(segmentedMessage.segments.length); // Update the state with the number of segments
        };

        const autoResize = (e) => {
            e.target.style.height = 'inherit';
            e.target.style.height = `${e.target.scrollHeight}px`;
        }

        return (
            <div>
                {
                    previewMode ?
                        <>
                            {imageUrl && <><Card style={{
                                maxWidth: '80%',
                                backgroundColor: '#E5E5EA',
                                borderRadius: '18px',
                                fontSize: '0.95rem',
                                whiteSpace: 'pre-wrap', /* CSS property to respect newlines and spaces */
                                wordBreak: 'break-word' /* Ensures long words do not overflow */
                            }}><img src={imageUrl} alt="Uploaded preview" style={{ borderRadius: '18px', maxWidth: '100%', maxHeight: 'Auto' }} /></Card></>}
                            <Card className="p-2 mt-1" style={{
                                width: '90%',
                                backgroundColor: '#E5E5EA',
                                borderRadius: '18px',
                                fontSize: '0.95rem',
                                whiteSpace: 'pre-wrap', /* CSS property to respect newlines and spaces */
                                wordBreak: 'break-word' /* Ensures long words do not overflow */
                            }}>
                                {smsText.length === 0 ? 'No content' : smsText}
                            </Card>
                        </> :
                        <>
                            <textarea
                                style={{ width: '100%' }}
                                className="rounded-card textarea-autosize"
                                placeholder="Type your multi-line SMS here..."
                                value={smsText}
                                onChange={handleMessageChange}
                                onInput={autoResize}
                            />
                            <small className={smsText.length > 1600 ? ' text-danger font-weight-bold' : ''}>
                                Count: {smsText.length}
                            </small>
                            <br />

                            <Input className="rounded-card" type="file" onChange={handleImageUpload} />
                            {imageUrl && <><div className="text-center"><img src={imageUrl} alt="Uploaded preview" style={{ maxWidth: '200px', maxHeight: '200px' }} /></div><br /></>}
                        </>
                }

                <br />
                <br />
                {
                    messageSending ?
                        <>
                            <Card className="rounded-card text-center" style={{ border: '1px solid #2E7D32', backgroundColor: '#C8E6C9' }}>
                                <span className="font-weight-bold">✅ Your SMS blast is now being sent! </span>
                                <br /><br />
                                Reports will be visible on this page soon.
                                <br /><br />
                                <span className="font-weight-bold">Estimated Cost: </span> {estCost}
                            </Card>
                        </> :
                        <>
                            {
                                imageLoading ?
                                    <>
                                        <div className="text-center">
                                            <InfinitySpin
                                                type="ThreeDots"
                                                color="#00BFFF"
                                                height={100}
                                                width={100}
                                            />
                                            <p>📸 Uploading your image...</p>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {
                                            loading ?
                                                <>
                                                    <Card className="rounded-card text-center" style={{ border: '1px solid #2E7D32', backgroundColor: '#C8E6C9' }}>
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}>
                                                            <InfinitySpin
                                                                type="ThreeDots"
                                                                color="white"
                                                                height={100}
                                                                width={100}
                                                            />
                                                        </div>

                                                        <br />
                                                        <span className="font-weight-bold">📲 Your SMS blast has been started!</span>
                                                        <br /><br />
                                                        It usually takes around 10 minutes to complete.
                                                        <br /><br />
                                                        <span className="font-weight-bold">Estimated Cost: </span> {estCost}
                                                    </Card>
                                                </> :
                                                <>
                                                    {
                                                        previewMode ?
                                                            <>
                                                                <Button
                                                                    style={{
                                                                        width: '100%', background: 'linear-gradient(to right, #E91E63, #FF4081)',
                                                                        color: 'white',
                                                                    }}
                                                                    className="rounded-card"
                                                                    onClick={sendSMS}>Send SMS 📲</Button>
                                                            </> :
                                                            <>
                                                            {
                                                                smsText.length > 1600 ? 
                                                                <>
                                                                 <Button
                                                                    style={{ width: '100%' }}
                                                                    className="rounded-card font-weight-bold text-danger"
                                                                   >SMS Too Large</Button>
                                                                </>
                                                                :
                                                                <>
                                                                 <Button
                                                                    style={{ width: '100%' }}
                                                                    className="rounded-card disabled"
                                                                    onClick={() => { setPreviewMode(true) }}>Preview</Button>
                                                                </>
                                                            }
                                                               
                                                            </>
                                                    }
                                                </>
                                        }
                                    </>

                            }

                        </>
                }

                <div>
                    <br />
                    <Row>
                        <Col>
                            Estimated Cost: <span className="font-weight-bold">{estCost}</span><br />
                            <small><span style={{ fontStyle: 'italic' }}>vs SimpleTexting: <span className="font-weight-bold">{simpleTextingCost}</span> </span></small>
                        </Col>
                        <Col className="text-right">
                            Total Contacts: <span className="font-weight-bold">~{contactCount}</span>
                        </Col>
                    </Row>
                    {
                        (segments > 3 && imageUrl.length == 0) &&
                        <>
                            <Card body style={{ borderRadius: '10px', border: '1px solid red', backgroundColor: "#FFF3EE", color: 'red' }} className="font-weight-bold">
                                🚨 Cut costs by adding an image to this SMS blast!
                            </Card>
                        </>
                    }

                </div>
            </div >
        );
    }

    function SMSBlacklist() {
        const [blacklist, setBlacklist] = useState([]);
        const [filteredNumbers, setFilteredNumbers] = useState([]);
        const [searchTerm, setSearchTerm] = useState("");
        const [selectedNumbers, setSelectedNumbers] = useState({});
        const blacklistRef = doc(db, "Jobs", "SMS Blacklist");

        useEffect(() => {
            const fetchData = async () => {
                const docSnap = await getDoc(blacklistRef);
                if (docSnap.exists()) {
                    const numbers = docSnap.data().phone_numbers;
                    setBlacklist(numbers);
                    setFilteredNumbers(numbers);
                }
            };
            fetchData();
        }, []);

        useEffect(() => {
            const filtered = blacklist.filter(number =>
                number.includes(searchTerm.replace(/\D/g, ''))
            );
            setFilteredNumbers(filtered);
        }, [searchTerm, blacklist]);

        const handleCheckboxChange = (number) => {
            setSelectedNumbers(prev => ({
                ...prev,
                [number]: !prev[number]
            }));
        };

        const addNumber = async () => {
            // Replace this with your logic to input a new number
            const newNumber = prompt("Enter new phone number:");
            if (newNumber) {
                const updatedBlacklist = [...blacklist, newNumber];
                setBlacklist(updatedBlacklist);
                await updateDoc(blacklistRef, { phone_numbers: updatedBlacklist });
            }
        };

        const deleteSelectedNumbers = async () => {
            const remainingNumbers = blacklist.filter(number => !selectedNumbers[number]);
            setBlacklist(remainingNumbers);
            setSelectedNumbers({});
            await updateDoc(blacklistRef, { phone_numbers: remainingNumbers });
        };

        return (
            <>
                <div style={{ display: 'flex', alignItems: 'center', background: '#f7f7f7', padding: '10px', borderRadius: '5px' }}>
                    <input
                        type="text"
                        placeholder="Search..."
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ flexGrow: 1, border: '1px solid #ddd', borderRadius: '5px', padding: '10px', marginRight: '10px' }}
                    />
                    <button onClick={addNumber} style={{ padding: '10px 15px', background: '#5cb85c', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                        +
                    </button>
                </div>
                {Object.keys(selectedNumbers).some(k => selectedNumbers[k]) && (
                    <button onClick={deleteSelectedNumbers} style={{ marginTop: '10px', padding: '10px 15px', background: '#d9534f', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                        Delete Selected
                    </button>
                )}
                <ul style={{ maxHeight: 'calc(12 * 1.5em)', overflowY: 'auto', listStyleType: 'none', padding: '10px', marginTop: '10px', border: '1px solid #ddd', borderRadius: '5px' }}>
                    {filteredNumbers.map((number, index) => (
                        <li key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #eee', padding: '8px 0' }}>
                            <span>{number}</span>
                            <input
                                type="checkbox"
                                checked={!!selectedNumbers[number]}
                                onChange={() => handleCheckboxChange(number)}
                                style={{ marginLeft: '10px' }}
                            />
                        </li>
                    ))}
                </ul>
            </>
        );
    }


    function SMSHistory() {
        const [blasts, setBlasts] = useState([]);
        const [modal, setModal] = useState(false);
        const [selectedBlast, setSelectedBlast] = useState(null);

        const toggleModal = () => setModal(!modal);

        const openPopup = (blast) => {
            console.log(blast)
            setSelectedBlast(blast);
            toggleModal();
        };

        useEffect(() => {
            // Fetch the data on initial load
            const fetchData = async () => {
                const docRef = doc(db, 'Jobs', 'Logs', 'SMS', 'Blasts');
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    // If the document exists, parse the data
                    let blastsData = Object.values(docSnap.data());

                    // Sort the blasts by date
                    blastsData.sort((a, b) => b.datetime - a.datetime);

                    // Set the sorted data to state
                    setBlasts(blastsData);
                } else {
                    console.log("No such document!");
                }
            };

            fetchData();
        }, []);

        return (
            <>
                <Table hover striped bordered>
                    <tr className="text-black">
                        {
                            isMobile ?
                                <>
                                    <th>Date</th>
                                    <th>Messages Sent</th>
                                </> :
                                <>
                                    <th>Date</th>
                                    <th>Successful Sends</th>
                                    <th>Failed Sends</th>
                                    <th>Target Audience</th>
                                </>
                        }

                    </tr>
                    {blasts.map((blast, index) => (
                        <tr key={index} className="text-black">
                            {
                                isMobile ?
                                    <>
                                        <td onClick={() => openPopup(blast)}>
                                            {moment.unix(Number(blast.datetime / 1000)).format("MM/DD/YYYY")}
                                            <br />
                                            <small>
                                                {moment.unix(Number(blast.datetime / 1000)).format("@ h:mm A")}
                                            </small>
                                        </td>
                                        <td>
                                            {blast.success}
                                            <br />
                                            <small><a href={blast.success_link}>Report</a></small>
                                        </td>
                                    </> :
                                    <>
                                        <td onClick={() => openPopup(blast)}>
                                            {moment.unix(Number(blast.datetime / 1000)).format("MM/DD/YYYY")}
                                            <br />
                                            <small>
                                                {moment.unix(Number(blast.datetime / 1000)).format("@ h:mm A")}
                                            </small>
                                        </td>
                                        <td>
                                            {blast.success}
                                            <br />
                                            <small><a href={blast.success_link}>Report</a></small>
                                        </td>
                                        <td>
                                            {blast.fail}
                                            <br />
                                            <small><a href={blast.fail_link}>Report</a></small>
                                        </td>
                                        <td>
                                            {blast.target_audience == 'all' ? 'All contacts' : blast.target_audience}
                                        </td>
                                    </>
                            }

                        </tr>
                    ))}
                </Table>
                <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                    <Card className="rounded-card">
                        {
                            selectedBlast && <img
                                src={selectedBlast.imageUrl}
                                style={{
                                    maxHeight: '200px',
                                    objectFit: 'contain'
                                }}
                            />
                        }
                        <pre style={{ fontSize: '0.8em', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{selectedBlast ? selectedBlast.message : 'No message available'}</pre>
                    </Card>
                </Modal>

            </>
        );
    }





    return (
        <div className="App">
            <Container className="text-left">
                <br />
                {
                    !isMobile &&
                    <>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={greenBot} alt="Green Bot" style={{ height: '10em', marginRight: '5px' }} />
                            <div>
                                <h2 className="bold-text text-black">Marketing Bot</h2>
                                <p className=" text-black rounded-card" style={{ backgroundColor: '#fff' }}>I'm your AI Marketing Bot, handling SMS blasts, promotions, and social chatter to keep your brand buzzing!</p>
                            </div>
                        </div>
                    </>
                }

                <br />

                {/* <Promotion /> */}
                <br />
                <h3 className="bold-text text-black">SMS Blast Tool</h3>
                <SimpleCard id="" running="" title="Send a single SMS blast" description="Send an SMS blast to all of your contacts whenever you want!" open="true">
                    <OneOffSMS />
                </SimpleCard>
                <br />
                <SimpleCard id="" running="" title="View my SMS blocklist" description="" open="true">
                    <SMSBlacklist />
                </SimpleCard>
                <br />
                <SimpleCard id="" running="" title="View my SMS history" description="" open="true">
                    <SMSHistory />
                </SimpleCard>
            </Container>
        </div>
    );
}

export default SMSBlast;